import React, { useState,useRef, useEffect  } from 'react';
import { Radio,message,Spin,Cascader } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import  Cookies from 'js-cookie'
import { updateUserInfo,UploadImg,getUserInfo,getUserCity } from 'api'
const UserInfo = () =>{
    const [userinfo,setUserinfo] = useState(JSON.parse(Cookies.get('userinfo')))
    const [loadingIcon,setloadingIcon] = useState(false)
    const imgLoad = useRef (null)
    const [options,setCity] = useState([]);
      useEffect(()=>{
        getUserCity({}).then(res=>{
            if(res.code===200) {
                setCity(res.data)
            }
        })
      },[])
    function handleUpdateUserInfo() {
        let data = userinfo 
        if(!data.nickname) {
            message.error('请输入昵称')
            return
        }
        if(!data.username) {
            message.error('请输入姓名')
            return
        }
        if(!data.avatar) {
            message.error('请上传头像')
            return
        }
       
        if(data.sex==='') {
            message.error('请选择性别')
            return
        }
        if(!data.province || !data.city || !data.county) {
            message.error('请选择地址')
            return
        }
        delete data['id']
        delete data['message_flag']
        delete data['user_type']
        updateUserInfo(data).then(res=>{
            if(res.code===200) {
                message.success('更新成功')
                getUserInfo({}).then(res=>{
                    if(res.code===200) {
                        Cookies.set('userinfo',JSON.stringify(res.data))
                    }
                })
            }else{
                message.error(res.msg)
            }
        })
    }
    function handleUploadImg(e) {
       
       
        let file = e.target.files[0]
        if(!file) {
            return
        }
         setloadingIcon(true)
        UploadImg({file:file}).then(res=>{
            if(res.code===200) {
               
                setUserinfo({...userinfo,avatar:res.data.image})
                 imgLoad.current.onload = (() => {
                    setloadingIcon(false)
                // 图片加载成功后的回调
                })
            }else{
                setloadingIcon(false)
            }
        })
    }
    function handeleAddrOnChange (e) {
        setUserinfo({...userinfo,province:e[0],city:e[1],county:e[2]})
    }
    return(
        <div className="userinfo">
            <div className="user_item">
                <p>昵称</p>
                <input type="text" value={userinfo.nickname} onInput={(e)=>setUserinfo({...userinfo,nickname:e.target.value})} placeholder="请输入"/>
            </div>
            <div className="user_item">
                <p>姓名</p>
                <input type="text" value={userinfo.username} onInput={(e)=>setUserinfo({...userinfo,username:e.target.value})} placeholder="请输入"/>
            </div>
            <div className="user_item_img">
                <p>头像</p>
                <div className='upload cursor'>
                    {!userinfo.avatar?
                    <UploadOutlined/>:
                    <div className='img'>
                         <img src={userinfo.avatar} ref={imgLoad} alt="" />
                         <h4>更换图片</h4>
                         {loadingIcon?<div className='Spin'><Spin /></div>:''}
                    </div>
                    }
                    <input type="file" className='cursor' onChange={(e)=>handleUploadImg(e)}/>
                </div>
            </div>
            <div className="user_item">
                <p>性别</p>
                <div className="radio">
                <Radio.Group onChange={(e)=>setUserinfo({...userinfo,sex:e.target.value})} value={userinfo.sex}>
                    <Radio value={1}>男</Radio>
                    <Radio value={2}>女</Radio>
                </Radio.Group>
                </div>
            </div>
            <div className="user_item">
                <p>地址</p>
                <Cascader options={options} value={[userinfo.province,userinfo.city,userinfo.county]} fieldNames={{label:'city_name', value: 'city_name', children: 'son'}} onChange={handeleAddrOnChange} placeholder="请选择地址" />
                {/* <input type="text" value={userinfo.addr} onInput={(e)=>setUserinfo({...userinfo,addr:e.target.value})} placeholder="请输入"/> */}
            </div>
            <div className="user_item">
                <p>邮箱</p>
                <input type="text" value={userinfo.email} onInput={(e)=>setUserinfo({...userinfo,email:e.target.value})} placeholder="请输入"/>
            </div>
            <div className="user_item">
                <p>年龄</p>
                <input type="text" value={userinfo.age} onInput={(e)=>setUserinfo({...userinfo,age:e.target.value})} placeholder="请输入"/>
            </div>
            <div className='user_btn cursor' onClick={()=>{handleUpdateUserInfo()}}>保存</div>
        </div>
    )
}
export default UserInfo