import React from 'react';
import './index.scss'
const Card = (props) => {
    return(
        <div className='card' style={{background:props.type==='1'?'rgba(247, 248, 250, 1)':''}}>
            <div className='card_img'>
                <img src={props.item.pic} alt="" />
                <div className={props.item.is_free==1?'corner charge':'corner free'} ></div>
            </div>
            <div className='card_info'>
                <div className='card_top'>
                    <div className='name'>{props.item.name}</div>
                    <div className='time'>{props.item.create_time}</div>
                </div>
                <div className='info'>
                    {props.item.desc}
                </div>
            </div>
           
        </div>
    )
} 
export default Card