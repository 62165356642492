import './index.scss'
import { useState,useEffect,useRef } from 'react'
import { useNavigate  } from 'react-router-dom';
import Tab from 'components/tab'
import Pay from 'components/pay'
import { getLiveList } from 'api'
import { message } from 'antd';
var winH = document.documentElement.clientHeight //获取设备高度
const Home =()=>{
    const tabs = ['正在直播','直播预约','往期回看']
    const [tabStu,setTabStu] = useState(0)
    const [page,setPage] = useState(1)
    const navigate = useNavigate()
    const [payPopStu,setPayPopStu] = useState(false)
    const [list,setList] = useState([])
    const [detail,setDetail] = useState({})
    const more = useRef()
    const [loadText,setLoadText] = useState('查看更多')
    useEffect(()=>{
        function handleGetLiveList() {
            getLiveList({
                page:page,
                flag:tabStu===0?1:tabStu===1?3:tabStu===2?2:'',
            }).then(res=>{
                if(res.code===200) {
                    console.log(list)
                    if(!res.data||res.data.length===0){
                        setLoadText('没有更多数据')
                    }
                    setList([...list,...res.data])
                    winH=document.documentElement.clientHeight
                }
            })
        }
        handleGetLiveList()
    },[tabStu,page])
    useEffect(() => {
        var timer=null
        function handle(params) {
            return function () {
            if(timer){
                clearTimeout(timer)
            }
            timer = setTimeout(() => {
                setPage(page+1)
            }, 500);
            }
        } 
        const scrollChange = (e) => {
            console.log(parseInt(more.current.getBoundingClientRect().top),winH)
            if(parseInt(more.current.getBoundingClientRect().top) <= winH){//getBoundingClientRect方法可获取节点距离窗口的位置
                setLoadText('加载中...')
                handle()()
            }
          }
        // 滚动条滚动时触发
        window.addEventListener("scroll",scrollChange)
        return () => {
            window.removeEventListener('scroll', scrollChange,false)
        }
      }, [page])
      function handleReset(n,o) {
        if(n===o) {
            return
        }
        setList([]);
        setLoadText('查看更多')
        setPage(1);
    }
    function handleOpenUrl(v) {
        if(tabStu===0) {
            navigate('/detail?id='+v.id+'&stu=2&key=live')
        }else if(tabStu===1) {
            if(v.is_pay===1) {
                message.info('您已预约了，直播开启前15分钟将会通过消息推送方式提示您')
                return
            }
            setDetail(v)
            setPayPopStu(true)
        }else if(tabStu===2) {
            navigate('/detail?id='+v.id+'&stu=3&key=back')
        }  
    }
    return (
        <div className='live'>
            {detail?<Pay popType='2' detail={detail} payPopStu={payPopStu} setPayPopStu={setPayPopStu}/>:""}
            <div className='live_tab'>
                <Tab tabs={tabs} tabStu={tabStu} setTabStu={setTabStu} handleReset={handleReset}/>
            </div>
            <div style={{height:'94px'}}></div>
            <div className='live_list'>
                {
                    list.map((v,i)=>{
                        return(
                            <div className='live_item cursor' onClick={()=>{handleOpenUrl(v)}}>
                                <div className='live_cover'>
                                    <img className='img' src={v.live_cover || require('images/list_img.png')} alt="" />
                                    {tabStu===0&&v.live_status==1?<img className='icon' src={require('images/living.png')} alt="" />:""}
                                </div>
                                <div className='live_info'>
                                    <div className='title'>
                                        <h2>{v.live_name}</h2>
                                        <p>{tabStu===0||tabStu===1?v.android_price==='0.00'?'免费':'￥'+v.android_price:v.playback_android_price==='0.00'?'免费':'￥'+v.playback_android_price}</p>
                                    </div>
                                    <div className='speak'>
                                        <div className='name'>{v.live_username}</div>
                                        {/* <div className='lable'>资深讲师</div> */}
                                    </div>
                                    <div className='introduce'>
                                        {v.live_desc}
                                    </div>
                                    <div className='bot'>
                                       {tabStu===0?v.number+'人学习':tabStu===1?'开始时间：'+v.start_time:'直播时间：'+v.start_time} 
                                    </div>
                                </div>
                                
                            </div>
                        )
                    })
                }
                
            </div>
            <div ref={more} onClick={()=>(loadText==='查看更多'?setPage(page+1):'')} className="bottom_more cursor">
                {loadText}
            </div>
        </div>
    )
}
export default Home