import React,{ useState,useEffect } from 'react';
import './index.scss'
import { Carousel } from 'antd';
import { getBanner } from 'api'
import { useNavigate } from 'react-router-dom';
const Banner = () =>{
    const [imgList,setImgList] = useState([])
    const go = useNavigate()
    function handleGetBanner() {
        getBanner({
            type:5
        }).then(res=>{
            if(res.code===200) {
                setImgList(res.data)
            }
        })
    }
    useEffect(()=>{
        handleGetBanner()
    },[])
    function handleGo(v) {
        if(v.path_type==5) {
            go('/detail?id='+v.course_id+'&stu=1&key=home')
        }else{
            go('/agreement?stu=3&id='+v.id)
        }
    }
    return(
        <div className='banner' >
             <Carousel autoplay dots>
                {imgList.map((v,i)=>{
                    return(
                        <div className='banner_item cursor' key={i} onClick={()=>handleGo(v)}>
                            <img src={v.show_pic}  alt="" />
                        </div>
                    )
                })}
               
               
            </Carousel>
        </div>
    )
}
export default Banner