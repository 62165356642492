import React, { useState, useEffect, useRef } from 'react';

import Banner from 'components/banner'
import Card from 'components/card'
import Tab from 'components/tab'
import { useNavigate, useSearchParams } from 'react-router-dom';
import './index.scss'
import { getHomeList } from 'api'
var winH = document.documentElement.clientHeight //获取设备高度
const Home = () => {
    const tabs = ['全部', '免费', '收费']
    const [tabStu, setTabStu] = useState(0)
    const [page, setPage] = useState(1)
    const [tabLeftStu, setTabLeftStu] = useState(0)
    const [tabLeftIndex, setTabLeftIndex] = useState(0)
    const navigate = useNavigate()
    const [types, setTypes] = useState([])
    const [list, setList] = useState([])
    const [search] = useSearchParams()
    const more = useRef()
    const [loadText, setLoadText] = useState('查看更多')
    const [keyword, setkeyword] = useState('')
    //防抖函数

    //下拉加载

    useEffect(() => {
        var timer = null
        function handle(params) {
            return function () {
                if (timer) {
                    clearTimeout(timer)
                }
                timer = setTimeout(() => {
                    setPage(page + 1)
                }, 500);
            }
        }
        const scrollChange = (e) => {

            console.log(parseInt(more.current.getBoundingClientRect().top), winH)
            if (parseInt(more.current.getBoundingClientRect().top) <= winH) {//getBoundingClientRect方法可获取节点距离窗口的位置
                setLoadText('加载中...')
                handle()()
            }
        }
        // 滚动条滚动时触发
        window.addEventListener("scroll", scrollChange)
        return () => {
            window.removeEventListener('scroll', scrollChange, false)
        }
    }, [page])
    function handleReset(n, o) {
        if (n === o) {
            return
        }
        setList([]);
        setLoadText('查看更多')
        setPage(1);
    }
   
    useEffect(() => {
       
        function handleGetHomeList() {
            //处理关键词搜索
            if (search.get('keyword') !== keyword) {
                setkeyword(search.get('keyword'))
                handleReset(search.get('keyword'), keyword)
                return
            }
            getHomeList({
                page: page,
                type_id: tabLeftStu,
                subclass_id:tabStu,
                search: keyword,
            }).then(res => {
                if (res.code === 200) {
                    console.log(list)
                    var arr = [...list]
                    res.data.list.map(item => {
                        return arr.push(item)
                    })
                    if (res.data.list.length === 0) {
                        setLoadText('没有更多数据')
                    }
                    setList(arr)
                    winH = document.documentElement.clientHeight
                }
            })
        }
        if(tabLeftStu ){
            handleGetHomeList()
        }
    }, [page, tabStu, tabLeftStu, search, keyword]);
    useEffect(()=>{
        getHomeList({
            page: page,
            search: keyword,
        }).then(res => {
            if (res.code === 200) {
                setTypes(res.data.types)
                setTabLeftStu(res.data.types[0].id)
                setTabLeftIndex(0)
            }
        })
    },[])
    return (
        <div style={{ paddingBottom: '20px' }}>
            <Banner />
            <div className='home_tab_list'>
                <div className='home_tab_list_top'>
                    <div className='home_tab_list_box'>
                    {
                        types.map((v, i) => {
                            return (
                            <div key={i} className={tabLeftStu===v.id?'home_tab_list_top_item cursor active1 cursor':'home_tab_list_top_item cursor'} onClick={() => { handleReset(tabLeftStu, v.id); setTabLeftStu(v.id);setTabStu(0);setTabLeftIndex(i);}}>
                                {v.name}
                                {tabLeftStu===v.id?<i></i>:''}
                            </div>
                            
                        )
                        })
                    }
                    
                    </div>
                </div>
                <div className='home_tab_list_bottom'>
                    {
                        (types.length!=0&&types[tabLeftIndex].child||[]).map((v, i) => { 
                            return (
                                <div className={tabStu===v.id?'home_tab_list_bottom_item cursor active2':'home_tab_list_bottom_item cursor'} onClick={()=>{handleReset(tabStu, v.id);setTabStu(v.id)}}>
                                     {v.name}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {/* <Tab tabs={tabs} tabStu={tabStu} source='home' setTabStu={setTabStu} handleReset={handleReset} /> */}
            <div className='home_content'>
                {/* <div className='home_left'>
                    <div className={tabLeftStu === 0 ? 'home_left_item active' : 'home_left_item'} onClick={() => { handleReset(tabLeftStu, 0); setTabLeftStu(0) }}>全部</div>
                    {
                        types.map((v, i) => {
                            return (

                                <div key={i} className={tabLeftStu === v.id ? 'home_left_item active' : 'home_left_item'} onClick={() => { handleReset(tabLeftStu, v.id); setTabLeftStu(v.id) }}>{v.name}</div>
                            )
                        })
                    }
                </div> */}
                <div className='home_right'>
                    {list.map((v, i) => {
                        return (
                            <div key={i} onClick={() => { navigate('/detail?id=' + v.id + '&stu=1&key=home') }} className='home_right_item cursor'>
                                <Card item={v} />
                            </div>
                        )
                    })}
                </div>
            </div>
            <div ref={more} onClick={() => (loadText === '查看更多' ? setPage(page + 1) : '')} className="bottom_more cursor">
                {loadText}
            </div>
        </div>
    )
}
export default Home