import './index.scss'
const Tab = (props) => {
    return(
        <div className='home_tab'>
                <div className='tab_list' style={{width:props.source==='home'?'340px':''}}>
                    {
                        props.tabs.map((v,i)=>{
                            return(
                                <div key={i} className={props.tabStu===i?'tab_list_item active':'tab_list_item'} onClick={()=>{if(props.handleReset)props.handleReset(props.tabStu,i);props.setTabStu(i)}}>{v}</div>
                            )
                        })
                    }
                    <div className='tab_list_item_bot' style={{transform:'translateX('+props.tabStu*(props.source==='home'?115:140)+'px)'}}></div>
                </div>
            </div>
    )
} 
export default Tab