import './index.scss'
import { useState,useEffect } from 'react'
import { getOrderPay } from 'api'
import { QRCode,message } from 'antd';
const Pay = (props) =>{
    const [payStu,setPayStu] = useState(1)
    const [codeUrl,setCodeUrl] = useState('')
    const [payUrl,setPayUrl] = useState('')
    function handleGetOrderPay() {
        getOrderPay({
            type:props.popType==='1'?5:10,
            oid:props.detail.id,
            pay_type:payStu===1?2:1,
            alipay_return_url:payStu===2?encodeURIComponent(window.location.href):''
        }).then(res=>{
            if(res.code===200) {
                if(payStu===1) {
                    setCodeUrl(res.data.code_url)
                }else{
                    setPayUrl(res.data.code_url)
                    // window.location.href=res.data.code_url
                }
               
            }else if(res.code===201) {
                message.success('预约成功！')
                props.setPayPopStu(false)
            }
        })
    }
    useEffect(()=>{
        if(props.detail.id&&props.payPopStu&&props.detail.android_price!=='0.00') {
            handleGetOrderPay()
        }
    },[payStu,props])
    function handleConfirm() {
        if(payStu==2) {
            window.location.href=payUrl
        }else{
            window.location.reload( )
        }
       
    }
    return(
        <div>
          {props.payPopStu?
            <div className="pay">
                <div className='pay_con' style={{height:props.popType==='3' || props.detail.android_price==='0.00'?'417px':''}}>
                    <div className='pay_title'>
                        <h2>{props.popType==='1'?'购买详情':'预约详情'}</h2>
                        <img className='cursor' onClick={()=>props.setPayPopStu(false)} src={require('images/login_close.png')} alt="" />
                    </div>
                    {props.popType==='3'?
                    <div className='warning'>
                        <img src={require('images/warning.png')} alt="" />
                        <p>直播还未开始，请等待，直播开启前15分钟将会通过消息推送方式提示您</p>
                    </div>
                    :''}
                    <div className='pay_info'>
                        <img src={props.detail.cover || props.detail.live_cover} alt="" />
                        <div className='right'>
                            <div className='name'>{props.detail.name||props.detail.live_name}</div>
                            {props.popType==='1'?
                            <div className='info'>{props.detail.desc || props.detail.live_desc}</div>
                            :
                            <div className='msg'>
                                <div className='speak'>老师：{props.detail.live_username || props.detail.teacher_name}</div>
                                <div className='detail'>课程介绍：{props.detail.desc || props.detail.live_desc}</div>
                            </div>
                            }
                            <div className='price'>
                                <h4>￥{props.live_status===2?props.detail.playback_android_price:props.detail.android_price||props.detail.price || props.detail.money}</h4>
                                {props.popType!=='1'?<p>开播时间：{props.detail.start_time}</p>:""}
                            </div>
                        </div>
                    </div>
                    {props.popType!=='3'?
                    <div>
                        {props.detail.android_price==='0.00'||props.detail.money==='0.00'?'':
                        <div className='pay_price'>
                            <h3>实付金额</h3>
                            <p>￥{props.live_status===2?props.detail.playback_android_price:props.detail.android_price || props.detail.money}</p>
                        </div>}
                        {props.detail.android_price==='0.00'||props.detail.money==='0.00'?'':
                        <div className='pay_method'>
                            <p className='p'>支付方式</p>
                            <div className={payStu===1?'method_item cursor active':'method_item cursor'} onClick={()=>setPayStu(1)}>
                                <img src={require('images/pay_wx.png')} alt="" />
                                <p>微信支付</p>
                            </div>
                            <div className={payStu===2?'method_item cursor active':'method_item cursor'} onClick={()=>setPayStu(2)}>
                                <img src={require('images/pay_ali.png')} alt="" />
                                <p>支付宝支付</p>
                            </div>
                        </div>}
                        {props.detail.android_price==='0.00'||props.detail.money==='0.00' || payStu===2? '':
                        <div className='pay_code'>
                            <QRCode value={codeUrl} />
                            <p>二维码支付</p>
                        </div>}
                        <div className='pay_btn'>
                            <div className='pay1 cursor' onClick={()=>props.setPayPopStu(false)}>取 消</div>
                            {props.detail.android_price==='0.00'||props.detail.money==='0.00'?
                            <div className='pay2 cursor' onClick={()=>handleGetOrderPay()}>确认预约</div>
                            :
                            <div className='pay2 cursor' onClick={()=>handleConfirm()}>确认支付</div>}
                        </div>
                    </div>
                    : <div className='pay_btn'>
                            <div></div>
                            <div className='pay2 cursor' onClick={()=>props.setPayPopStu(false)}>确认</div>
                        </div>}
                </div>
            </div>:''}
        </div>
    )
}
export default Pay