import { useState,useEffect,useContext } from 'react'
import { useSearchParams,useNavigate } from 'react-router-dom'
import './index.scss'
import Video from 'components/video'
import Pay from 'components/pay'
import { getListDetail,courseCollect,sdkAppId,getLiveRecordDetail,getUserSig } from 'api'
import { message } from 'antd'
import TRTC from 'trtc-js-sdk';
import TIM from 'tim-js-sdk';
import { sdkAppIdIm }from 'api'
import TIMProfanityFilterPlugin from 'tim-profanity-filter-plugin';
import  Cookies from 'js-cookie'
import { loginStuService } from 'utils/loginStu';
import BulletScreen from 'rc-bullets';
import { Tabs } from 'antd';
var timer=null
const HomeDetail = () =>{
    const navigate = useNavigate()
    const LoginStuService = useContext(loginStuService);
    const [shareStu,setShareStu] = useState(false)
    const [search] = useSearchParams()
    const id = search.get('id')
    // 1 - 课程详情页 2 - 直播详情 3 - 其他详情(回放)
    const stu = search.get('stu')
    //home-课程详情 live-直播详情 back - 直播回放 order - 订单详情 collect - 收藏详情
    const key = search.get('key') 
    let tabList = stu==='1'?['目录','介绍']:stu==='2'?['讨论','介绍']:['介绍']
    const [tabStu,setTabStu] = useState(0)
    const [payPopStu,setPayPopStu] = useState(false)
    const [userinfo] = useState(Cookies.get('userinfo')?JSON.parse(Cookies.get('userinfo')):{})
    const [userSig,setUserSig] = useState(null)
    const [listStu,setListStu] = useState(0)
    const [detail,setDetail] = useState({
        course_video:[]
    })
    const [collect,setCollect] = useState('')
    const [remoteStreamType,setRemoteStreamType] = useState([])
    const [client,setClient] = useState(null);
    const [tim,setTim] = useState()
    const [groupID] = useState(id)
    const [group,setGroup] = useState({})
    const [groupUser,setGroupUser] = useState({})
    
    const [imList,setImList] = useState([])
    const [imList1,setImList1] = useState([])
    const [textValue,setTextValue] = useState('')
    const [personList,setPersonList] = useState([])
    const [videoBig,setVideoBig] = useState(false)
    const [barrage,setBarrage] = useState(false)
    // 弹幕屏幕
    const [screen1, setScreen1] = useState(null);
    const [muteAudio,setMuteAudio] =useState(true)
    const [items,setItems] =useState([]);
    const [url,setUrl] = useState()
    useEffect(() => {
        // 给页面中某个元素初始化弹幕屏幕，一般为一个大区块。此处的配置项全局生效
        let s = new BulletScreen('.barrage',{duration:20});
        console.warn(s)
        setScreen1(s);
    }, [videoBig]);
    useEffect(()=>{
        function handleGetUserSig () {
            getUserSig({uid:userinfo.id}).then(res=>{
                if(res.code===200) {
                    setUserSig(res.data.user_sig)
                }
            })
        }
       if(stu==='2'&&(detail.is_pay || detail.android_price==='0.00')) {
            handleGetUserSig ()
       }
    },[userinfo,stu,detail])
    useEffect(()=>{
        if(userSig) {
            setClient(TRTC.createClient({
                mode:'live',
                sdkAppId:sdkAppId,
                userId:userinfo.id+'',
                userSig:userSig
            }))
            setTim(TIM.create({SDKAppID:sdkAppIdIm}))
        }
    },[userSig,userinfo])
    useEffect(()=>{
        if(!tim) {
            return
        }
        function onMessageReceived(event) {
            var arr = JSON.parse(JSON.stringify(imList1))
            event.data.map((message) => {
                console.log('消息',message)
                if (message.type === TIM.TYPES.MSG_TEXT) {
                    arr.push(message)
                    // 文本消息 - https://web.sdk.qcloud.com/im/doc/zh-cn/Message.html#.TextPayload
                }else if (message.type === TIM.TYPES.MSG_GRP_SYS_NOTICE) {
                    let data = JSON.parse(message.payload.userDefinedField)
                    if(data&&data.type==0) {
                        setCollect(collect+1)
                    }
                    console.log('系统消息',JSON.parse(message.payload.userDefinedField))
                }
            })
            setImList1(arr)
        }
        function onSdkReady(event) {
            tim.updateMyProfile({
                nick: userinfo.nickname,
                avatar: userinfo.avatar,
                gender: userinfo.sex===1?TIM.TYPES.GENDER_MALE:TIM.TYPES.GENDER_FEMALE,
                // selfSignature: '我的个性签名',
            });
            tim.joinGroup({
                groupID:groupID,
            }).then(function(imResponse) {
                getMessageList()
                getGroupProfile()
                switch (imResponse.data.status) {
                case TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
                    break;
                case TIM.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
                    setGroup(imResponse.data.group)
                    console.log('加群成功群组资料',imResponse.data.group); // 加入的群组资料
                    break;
                case TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已经在群中
                    console.log('加群成功群组资料',imResponse.data.group);
                    break;
                default:
                    break;
                }
            }).catch(function(imError){
                console.warn('joinGroup error:', imError); // 申请加群失败的相关信息
            });
        };
        tim.setLogLevel(0); 
        tim.registerPlugin({'tim-profanity-filter-plugin': TIMProfanityFilterPlugin});
        tim.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);
        tim.on(TIM.EVENT.SDK_READY, onSdkReady);
        tim.login({
            userID:userinfo.id+'',
            userSig:userSig
        }).then(function(imResponse) {
            console.log(imResponse.data); // 登录成功
            if (imResponse.data.repeatLogin === true) {
                // 标识帐号已登录，本次登录操作为重复登录。v2.5.1 起支持
                console.log(imResponse.data.errorInfo);
            }
        }).catch(function(imError) {
            console.warn('login error:', imError); // 登录失败的相关信息
        });;
        return () => {
            tim.logout();
            tim.destroy();
            tim.quitGroup(groupID).then(function(imResponse) {
                console.log(imResponse.data.groupID); // 退出成功的群 ID
            }).catch(function(imError){
                console.warn('quitGroup error:', imError); // 退出群组失败的相关信息
            });
            clearInterval(timer)
            timer=null
            tim.off(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);
            tim.off(TIM.EVENT.SDK_READY, onSdkReady);
        }
    },[tim])
    function getGroupMemberList() {
        tim.getGroupMemberList({
            groupID:groupID,
            count:100,
            offset:0
        }).then(function(imResponse) {
            setPersonList(imResponse.data.memberList)
            console.log('群成员列表',imResponse.data.memberList); // 群成员列表
        }).catch(function(imError) {
            console.warn('getGroupMemberList error:', imError);
        });
    }
    function sendImMsg(text) {
        if(stu==='2'&&!detail.is_pay&&detail.android_price!=='0.00') {
            message.error('您还没有购买该课程！')
            return
        }
        if(textValue==='') {
            message.error('发送消息不能为空')
            return
        }
        let message1 = tim.createTextMessage({
            to: groupID,
            conversationType: TIM.TYPES.CONV_GROUP,
            payload: {
              text: textValue
            },
        });
        // 2. 发送消息
        tim.sendMessage(message1).then(function(imResponse) {
            // 发送成功
            var arr = imList
            arr.push(imResponse.data.message)
            setImList(arr)
            screen1.push(textValue);
            setTextValue('')
            console.log('监听消息',imList)
        }).catch(function(imError) {
            if(imError.code==10017) {
                message.error('你已被老师禁言，无法发送内容')
            }
            // 发送失败
            console.log('error',imError.code);
        });
    }
    function getGroupProfile() {
        tim.getGroupProfile({ groupID: groupID}).then(function(imResponse) {
            console.log('加群成功群组资料',imResponse.data.group)
             getGroupMemberProfile(imResponse.data.group.ownerID)
             setGroup(imResponse.data.group)
              getGroupMemberList()
             timer = setInterval(()=>{
                  getGroupMemberList()
              },5000)
        }).catch(function(imError) {
          console.warn('getGroupProfile error:', imError); // 获取群详细资料失败的相关信息
        });
    }
    function getGroupMemberProfile(id) {
        tim.getGroupMemberProfile({
            groupID: groupID,
            userIDList: [id], // 请注意：即使只拉取一个群成员的资料，也需要用数组类型，例如：userIDList: ['user1']
            // memberCustomFieldFilter: ['group_member_custom'], // 筛选群成员自定义字段：group_member_custom
        }).then(function(imResponse) {
            console.log('群成员',imResponse.data.memberList[0])
            setGroupUser(imResponse.data.memberList[0])
        }).catch(function(imError) {
            console.warn('getGroupMemberProfile error:', imError);
        });
    }
    useEffect(()=>{
        var arr = [...imList,...imList1]
        if(imList1.length>0) {
            screen1.push(imList1[imList1.length-1].payload.text);
        }
        setImList(arr)
    },[imList1])
    // stu,imList,groupID,userinfo,userSig,tim
    useEffect(()=>{
        if(!client&&stu!=='2') {
            return
        }
        function liveVideo(remoteStream,stu) {
            var arr = remoteStreamType
            if(stu==='a') {
                arr.push(remoteStream)
            }else if(stu==='r') {
                arr.map((v,i)=>{
                    if(v.getType() === remoteStream.getType()){
                            console.log('远player_'+v.getId(),document.getElementById(('player_'+v.getId())))
                        if(document.getElementById('player_'+v.getId())){
                    
                            document.getElementById('player_'+v.getId()).remove()
                        }
                        arr.splice(i,1)
                    }
                })
            }else if(stu==='u') {
                arr.map((v,i)=>{
                    if(v.getType() === remoteStream.getType()){
                        v = remoteStream
                    }
                })
            }
            console.log('远',arr)
            if(arr.length===1) {
                arr.map((v,i)=>{
                    console.log('远1',v.getId())
                    v.play('trts_video');
                })
                
            }else if(arr.length>1) {
                arr.map((v,i)=>{
                    if(v.getType() === 'main'){
                        v.play('trts_video1')
                    }else{
                        v.play('trts_video')
                    }
                })
            }
            setRemoteStreamType(arr)
        }
        try{
            client.on('stream-added',async event => {
                const remoteStream = event.stream;
                console.log('123123121231远端流增加 ', remoteStream);
                //订阅远端流
                await client.subscribe(remoteStream)
                liveVideo(remoteStream,'a')
                
            });
            client.on('stream-removed', event => {
                const remoteStream = event.stream;
                console.log('removed远端流删除: ',remoteStream);
                liveVideo(remoteStream,'r')
                
                
            });
            client.on('stream-updated', event => {
                const remoteStream = event.stream;
                console.log('updated远端流更新: ',remoteStream);
                liveVideo(remoteStream,'u')
            });
            client.join({ roomId:parseInt(groupID),role: 'audience' })
            .then(() => {
                console.log('进房成功'); 
            })
            .catch(error => {
                console.error('进房失败 ' + error);
            });
            client.on('client-banned', error => {
                console.error('client-banned observed: ' + error.message);
                message.error('因直播违规被下架')
                setTimeout(()=>{
                    navigate(-1)
                },1500)
                
                // client-banned observed: client was banned because of duplicated userId joining the room.
                // client-banned observed: client was banned because of you got banned by account admin
              });
              
        }catch(e) {

        }   
        return()=>{
            if(!client) {
                return
            }
            client.leave()
        }
    },[client])
    function handleSetListStu(i) {
        if(!Cookies.get('token')&&detail.course_video[i].is_free!==0) {
            LoginStuService.handleLoginStu(true)
            return
        }
        if(!detail.is_pay&&detail.course_video[i].is_free!==0){
            setPayPopStu(true)
        }else{
            setListStu(i)
        }
       
    }
    useEffect(()=>{
        function handleGetListDetail() {
            let fn = stu === '1'?getListDetail({course_id:id}):getLiveRecordDetail({live_id:id})
            fn.then(res=>{
                if(res.code===200) {
                    setDetail(res.data)
                    if(res.data.live_status==2) {
                        var item = []
                        if(res.data.record_video_url_json) {
                            res.data.record_video_url_json.map((v,i)=>{
                                item.push({key:v,label:"第"+(i+1)+"段"})
                            })
                        }
                    }
                    setItems(item)
                    setUrl(res.data.record_video_url)
                    if(!res.data.is_pay&&res.data.course_video&&res.data.course_video[0].is_free!==0&&stu==='1') {
                        setPayPopStu(true)
                    }else if(!res.data.is_pay&&stu==='3'&&res.data.playback_android_price!=='0.00') {
                        setPayPopStu(true)
                    }else if(!res.data.is_pay&&stu==='2'&&res.data.android_price!=='0.00') {
                        setPayPopStu(true)
                    }
                    if(stu==='2'&&res.data.live_status==2) {
                        navigate('/detail?id='+res.data.id+'&stu=3&key=back',{replace:true})
                    }
                }
            })
        }
        handleGetListDetail()
    },[id,stu,collect])
   
    function handleCourseCollect() {
        if(!Cookies.get('token')) {
            LoginStuService.handleLoginStu(true)
            return
        }
        courseCollect({course_id:detail.id}).then(res=>{
            if(res.code===200) {
                message.success(res.msg)
                //副作用
                setCollect(collect+1)
            }
        })
    }
    function shareToQq() {   // 分享到腾讯QQ
        console.log(window.location)
        var shareqqstring = 'https://connect.qq.com/widget/shareqq/index.html?url=' + encodeURIComponent(window.location.href) + '&title=' + detail.name + '&desc='+detail.desc+'&pics='+window.location.origin+'/logo2.png';
        window.open(shareqqstring);
    }
   function shareToQqZone() { // 分享到QQ空间
        var shareqqzonestring = 'https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=https://'+ encodeURIComponent(window.location.href.split('://')[1]) + '&sharesource=qzone&title=' + detail.name + '&summary=' + detail.desc + '&pics='+encodeURIComponent(window.location.origin+'/logo2.png')
        window.open(shareqqzonestring);
    }
    function shareToSina () {   // 分享到新浪微博
        var sharesinastring = 'https://service.weibo.com/share/share.php?url=' +  encodeURIComponent(window.location.href) +'&title=' + detail.name +  '&pic=' + encodeURIComponent(window.location.origin+'/logo2.png')+'&appkey=';
        window.open(sharesinastring);
    }
    function wxShareCopy() {
        const el = document.createElement('input')
    // 给input元素赋值需要复制的文本
        el.setAttribute('value', window.location.href)
        // 将input元素插入页面
        document.body.appendChild(el)
        // 选中input元素的文本
        el.select()
        // 复制内容到剪贴板
        document.execCommand('copy')
        // 删除input元素
        document.body.removeChild(el)
        message.success('分享链接复制成功')
    }
    function changeFullScreen() {
        const element = document.documentElement
        // 如果是全屏状态
        if (document.fullscreen) {
            // 如果浏览器有这个Function
            if (document.exitFullscreen) {
                document.exitFullscreen()
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen()
            }
        } else {
            // 如果浏览器有这个Function
            if (element.requestFullscreen) {
                element.requestFullscreen()
            } else if (element.webkitRequestFullScreen) {
                element.webkitRequestFullScreen()
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen()
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen()
            }
        }
        // 判断全屏状态的变量
        // this.fullscreen = !this.fullscreen
    }
    function handleMuteAudio() {
        remoteStreamType.map((v,i)=>{
            if(v.getType() === 'main') {
                if(muteAudio) {
                    v.muteAudio()
                    setMuteAudio(false)
                }else{
                    v.unmuteAudio()
                    setMuteAudio(true)
                }
            }
        })
    }
    function getMessageList(id){
        tim.getMessageList({
            conversationID:'GROUP'+(id || groupID),
          }).then(function(imResponse1) {
            const { messageList, isCompleted, nextReqMessageID } = imResponse1.data;
            console.log('messageList',messageList)
            var arr = JSON.parse(JSON.stringify(imList))
            messageList.map((v,i)=>{
                if(v.type!='TIMCustomElem') {
                    arr.push(v)
                }
            })
            console.log('监听消息1',arr)
            setImList(arr)
            if(!isCompleted) {
                getMessageList(nextReqMessageID)
            }
            // messageList - 消息列表
            // isCompleted - 拉取完成标识， true 已拉完，false 未拉完，可以通过 nextMessageSeq 的返回值进行续拉
            // nextMessageSeq - 续拉起始 sequence，当 isCompleted 返回 true 时，nextMessageSeq 返回空字符串
          });
    }
    const onChange = (key) => {
       setUrl(key)
      };
      
      
    return(
        <div className="home_detail">
            <Pay popType={stu==='1'?'1':'10'} detail={detail} payPopStu={payPopStu} setPayPopStu={setPayPopStu}/>
           {key==='home'?
           <div className="home_detail_nav">
                当前位置：首页 &gt; <span>课程详情</span>
            </div>
            :key==='live'?
            <div className="home_detail_nav">
                当前位置：直播课程 &gt;  <span>直播详情</span>
            </div>
            :key==='back'?
            <div className="home_detail_nav">
                当前位置：直播课程 &gt; <span>直播回放</span>
            </div>
             :key==='order'?
             <div className="home_detail_nav">
                 当前位置：我的 &gt; 我的订单 &gt; <span>订单详情</span>
             </div>
             :key==='collect'?
             <div className="home_detail_nav">
                当前位置：我的 &gt; 我的收藏 &gt; <span>收藏详情</span>
            </div>
            :''}
            <div className="detail_info">
                <div className="detail_header">
                    <h2>{detail.name||detail.live_name}</h2>
                    {stu==='1'?<h3>{detail.is_pay?'':detail.is_free==1?'付费':'免费'}</h3>:<h3>{detail.android_price==='0.00'?'免费':detail.android_price}</h3>}
                </div>
                <div className='detail_video'>
                    <div className='left'>
                        {stu==='2' || stu ==='3'?
                        <div className='discuss'> 
                            <div className='speaker'>
                                <div className='img'>
                                    <img src={detail.live_username_avatar} alt="" />
                                </div>
                                <p>{detail.live_username}老师</p>
                            </div>
                            <div className='flow'>
                                <div className='photo_list'>
                                    <div className='image'>
                                        <img src={personList[0]&&personList[0].avatar || require('images/MR_img.png')} alt="" />
                                    </div>
                                    <div className='image'>
                                         <img src={personList[1]&&personList[1].avatar || require('images/MR_img.png')} alt="" />
                                    </div>
                                    <div className='image'>
                                         <img src={personList[2]&&personList[2].avatar || require('images/MR_img.png')} alt="" />
                                    </div>
                                </div>
                                <div className='flow_people'>{stu==='3'?'累计'+(detail.number || personList.length)+'人观看':(detail.number || personList.length)+'人在看'}</div>
                            </div>
                        </div>
                        :''}
                        <div className={videoBig?'video_map_a video_map':'video_map'} >
                            <h4 className='endTips'>{detail.live_status===3?'直播已暂停':''}</h4>
                            <div id='trts_video' style={{display:remoteStreamType.length>0?'':'none'}}></div>
                            <div id='trts_video1' className='screen_video' style={{display:remoteStreamType.length>1?'':'none'}}></div>
                            {
                            stu!=='2'?
                            stu==='3'&&(detail.is_pay || detail.playback_android_price==='0.00') || stu==='1'?
                                <Video detail={detail} listStu={listStu} stu={stu} url={url}/>:''
                                :
                                detail.live_status!==3?
                                <div className='video_handle'>
                                    {/* 30:11 */}
                                    <p></p>
                                    <div className='handle_list'>
                                        <img alt='' onClick={()=>{setBarrage(!barrage)}} src={require(barrage?'images/open_d.png':'images/close_d.png')}/>
                                        <img alt='' onClick={()=>{handleMuteAudio()}} src={require(muteAudio?'images/soungs.png':'images/mute.png')}/>
                                        <img alt='' onClick={()=>{setVideoBig(!videoBig);changeFullScreen()}} src={require('images/video_big.png')}/>
                                    </div>
                                </div>
                                :''
                            }
                            {/*  */}
                            <div className='barrage' style={{opacity:stu==='2'&&videoBig&&barrage?'1':'0'}} ></div>
                           
                        </div>
                       
                        <div className='share_collect'>
                            <div className='click_icon cursor' onClick={()=>{!Cookies.get('token')?LoginStuService.handleLoginStu(true):setShareStu(true)}}>
                                <img src={require('images/share.png')} alt="" />
                                <p>分享</p>
                            </div>
                            {stu==='1'?<div className='click_icon cursor' onClick={()=>{handleCourseCollect()}}>
                                {/* collect_a */}
                                <img src={require('images/collect'+(detail.is_collect?'_a':'')+'.png')} alt="" />
                                <p>收藏</p>
                            </div>:''}
                            {shareStu?<div className='share_pop_bg' onClick={(e)=>{e.stopPropagation();setShareStu(false)}}></div>:''}
                            {shareStu?
                            <div className='share_pop'>
                                <h1>分享到</h1>
                                <div className='share_con cursor'>
                                    <div className='share_btn' onClick={()=>shareToQq()}>
                                        <img src={require('images/share_qq.png')} alt="" />
                                        <p>QQ好友</p>
                                    </div>
                                    <div className='share_btn cursor' onClick={()=>shareToQqZone()}>
                                        <img src={require('images/share_zone.png')} alt="" />
                                        <p>QQ空间</p>
                                    </div>
                                    <div className='share_btn cursor' onClick={()=>shareToSina()}>
                                        <img src={require('images/share_wb.png')} alt="" />
                                        <p>新浪微博</p>
                                    </div>
                                    <div className='share_btn cursor' onClick={()=>wxShareCopy()}>
                                        <img src={require('images/share_wx.png')} alt="" />
                                        <p>微信好友</p>
                                    </div>
                                    <div className='share_btn cursor' onClick={()=>wxShareCopy()}>
                                        <img src={require('images/share_circle.png')} alt="" />
                                        <p>微信朋友圈</p>
                                    </div>
                                </div>
                            </div>:""}
                        </div>
                    </div>
                    <div className='right'>
                        <div className='right_tab'>
                            {
                                tabList.map((v,i)=>{
                                    return(
                                        <div className={tabStu===i?'active right_tab_item cursor':'right_tab_item cursor'} onClick={()=>setTabStu(i)}>{v}</div>
                                    )
                                })
                            }
                            <div className="tab_item_bot" style={{transform:(stu==='1' || stu==='2'?'translateX('+(-35+tabStu*72)+'px)':'translateX(0))')}}></div>
                        </div>
                        {detail.live_status==2&&items.length>1? <Tabs defaultActiveKey={detail.record_video_url} items={items} style={{padding:'0 10px'}} onChange={onChange} />:''}
                        {tabStu===0&&stu==='1'?
                        <div className='catalogue'>
                            {detail.course_video.map((v,i)=>{
                                return(
                                    <div onClick={()=>{handleSetListStu(i)}} className={listStu===i?'active catalogue_item cursor':'catalogue_item cursor'}>
                                        <div className='left'>
                                            <img className='video_stop' src={require(`images/video_stop${listStu===i?'_a':''}.png`)} alt="" />
                                            <div className='num'>{i>8?(i+1):'0'+(i+1)}</div>
                                            <div className='name'>{v.name}</div>
                                        </div>
                                        <div className={listStu===i?'active time':'time'}>{v.playtime}</div>
                                        {detail.is_pay?'':<div className='icon'>
                                            <img src={require(`images/${v.is_free===1?'video_charge':'video_free'}.png`)} alt="" />
                                        </div>}
                                    </div>
                                )
                            })}
                        </div>
                        :tabStu===0&&stu==='2'?
                        <div>
                            <div className='talk_list'>
                                {imList.map((v,i)=>{
                                    return(
                                        <div className='talk_list_item' >
                                            <div className='item_con'>
                                                <div className='item_info'>
                                                    <div className='img'>
                                                    <img src={v.avatar} alt="" />
                                                    </div>
                                                    <p style={group.ownerID==v.from?{color:'rgba(0, 197, 229, 1)'}:{}}>{v.nick}</p>
                                                </div>
                                                <div className='talk_info'>
                                                    {v.payload&&v.payload.text}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            {detail.live_status!=3?<div className='talk_input'>
                                <textarea value={textValue} onInput={(e)=>setTextValue(e.target.value)} placeholder="请输入文字">
                                </textarea> 
                                <div className='talk_send cursor' onClick={()=>sendImMsg()}>发送</div>
                            </div>:''}
                        </div>
                        :(tabStu===0&&stu==='3') || tabStu===1?
                        <div className='des'>
                            <h2>直播介绍</h2>
                            <div className='des_info'>
                                {detail.desc || detail.live_desc}
                            </div>
                        </div>
                        :''}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HomeDetail