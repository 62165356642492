import {createContext,useState,useCallback } from 'react'

export const loginStuService = createContext(null);

export const useRootLoginStuService = () => {
    const [loginStu, setLoginStu] = useState(false);
    const handleLoginStu = useCallback((v) => {
        setLoginStu(v);
    }, []);
    return {
        loginStu,
        handleLoginStu,
    };
};