import React,{ useState,useEffect,useContext } from 'react';
import Card from 'components/card'
import { useNavigate  } from 'react-router-dom';
import { userCollect } from 'api'
var winH = document.documentElement.clientHeight //获取设备高度
const MyCollect = () =>{
    const navigate = useNavigate()
    const [list,setList] = React.useState([])
    const [page,setPage] = React.useState(1)
    const more = React.useRef()
    const [loadText,setLoadText] = React.useState('查看更多')
    React.useEffect(()=>{
        function handleUserCollect() {
            userCollect({page:page}).then(res=>{
                if(res.code===200) {
                    var arr = JSON.parse(JSON.stringify(list)) 
                    console.log(res.data)
                    res.data&&res.data.list.map(item=>{
                       return arr.push(item)
                    })
                    if((res.data&&res.data.list.length===0) || !res.data){
                        setLoadText('没有更多数据')
                    }
                    setList(arr)
                    winH=document.documentElement.clientHeight
                }
            })
        }
        handleUserCollect()
    },[page])
    React.useEffect(() => {
        var timer=null
        function handle(params) {
            return function () {
            if(timer){
                clearTimeout(timer)
            }
            timer = setTimeout(() => {
                setPage(page+1)
            }, 500);
            }
        } 
        const scrollChange = (e) => {
            console.log(parseInt(more.current.getBoundingClientRect().top),winH)
            if(parseInt(more.current.getBoundingClientRect().top) <= winH){//getBoundingClientRect方法可获取节点距离窗口的位置
                setLoadText('加载中...')
                handle()()
            }
          }
        // 滚动条滚动时触发
        window.addEventListener("scroll",scrollChange)
        return () => {
            window.removeEventListener('scroll', scrollChange,false)
        }
      }, [page])
     return(
        <div>
            <div className='collect'>
                {list.map((v,i)=>{
                    return(
                        <div key={i} onClick={()=>{navigate('/detail?id='+v.id+'&stu=1&key=collect')}} className='home_right_item cursor'>
                            <Card type='1' item={v}/>
                        </div>
                    )
                })}
                
            </div>
            <div ref={more} onClick={()=>(loadText==='查看更多'?setPage(page+1):'')} className="bottom_more cursor">
                    {loadText}
            </div>
        </div>
    )
}
export default MyCollect