import { POST,GET,FILE } from "server"

export const getLogin=(data)=>{
    return GET('/index.php/mapi/login/login', data)
} 
export const getRegister=(data)=>{
    return GET('/index.php/mapi/login/register', data)
} 
// 忘记密码
export const getForget=(data)=>{
    return GET('/index.php/mapi/login/forget_password', data)
} 
export const getNewPasswordTips=(data)=>{
    return GET('/index.php/mapi/login/renew_password', data)
} 
export const getBanner=(data)=>{
    return GET('/index.php/mapi/slide/get_slide_list', data)
} 
export const getBannerDetail=(data)=>{
    return GET('/index.php/mapi/slide/get_slide_detail', data)
} 
export const getHomeList=(data)=>{
    return GET('/index.php/mapi/course/get_course_list', data)
} 
export const getSearchHistory=(data)=>{
    return GET('/index.php/mapi/course_auth/get_course_search', data)
} 
export const getClearSearchHistory=(data)=>{
    return GET('/index.php/mapi/course_auth/empty_course_search', data)
} 
export const getListDetail=(data)=>{
    return GET('/index.php/mapi/course/get_course_detail', data)
} 
export const getLiveList=(data)=>{
    return GET('/index.php/mapi/live/user_live_record_list', data)
} 
export const getUserInfo=(data)=>{
    return GET('/index.php/mapi/user/get_user_info', data)
} 
export const updateUserInfo=(data)=>{
    return POST('/index.php/mapi/user/update_user_info', data)
} 

export const userCollect=(data)=>{
    return POST('/index.php/mapi/course_auth/user_collect', data)
} 
export const courseCollect=(data)=>{
    return POST('/index.php/mapi/course_auth/course_collect', data)
} 
export const userFeedback=(data)=>{
    return POST('/index.php/mapi/user/user_feedback', data)
} 
// 消息
export const messageList=(data)=>{
    return POST('/index.php/mapi/message/message_list', data)
} 
// 删除消息
export const deleteMessage=(data)=>{
    return POST('/index.php/mapi/message/delete_message', data)
} 
// 消息详情
export const getMessageDetail=(data)=>{
    return GET('/index.php/mapi/message/get_message_detail', data)
} 
//订单列表
export const getUserOrderList=(data)=>{
    return POST('/index.php/mapi/order/get_user_order_list', data)
} 
//code
export const oauthUserinfo=(data)=>{
    return POST('/mapi/oauth_login/oauth_userinfo', data)
} 
export const oauthMobile=(data)=>{
    return POST('/index.php/mapi/oauth_login/oauth_mobile', data)
} 
export const getLiveRecordDetail=(data)=>{
    return POST('/index.php/mapi/live/get_live_record_detail', data)
} 
export const getOrderPay=(data)=>{
    return POST('/index.php/mapi/order/order_pay', data)
} 
export const orderPreview=(data)=>{
    return POST('/index.php/mapi/order/order_preview', data)
} 



export const getConfig=(data)=>{
    return GET('/index.php/mapi/app/config', data)
} 
export const UploadImg=(data)=>{
    return FILE('/index.php/mapi/app/uploadFile', data)
} 
export const GetCode=(data)=>{
    return POST('/mapi/login/get_code', data)
} 
export const getUserSig=(data)=>{
    return POST('/mapi/tencent_trtc/get_user_sig', data)
} 
export const getSlideDetail=(data)=>{
    return POST('/index.php/mapi/slide/get_slide_detail', data)
} 
export const getUserCity=(data)=>{
    return POST('/index.php/mapi/app/user_city', data)
} 


export const sdkAppId = 1400784726
export const sdkAppIdIm = 1400784726