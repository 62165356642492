import React,{ useState,useEffect,useContext } from 'react';
import { useNavigate,useLocation,useSearchParams } from 'react-router-dom'
import './index.scss'
import classNames from 'classnames'
import { QuestionCircleOutlined } from '@ant-design/icons';
import Clean from 'components/clean'
import  Cookies from 'js-cookie'
import { getSearchHistory,getClearSearchHistory } from 'api'
import { message } from 'antd'
import { loginStuService } from 'utils/loginStu';
const Nav = () =>{
    const navigate = useNavigate()
    const LoginStuService = useContext(loginStuService);
    const {pathname} = useLocation(); 
    const [search] = useSearchParams() 
    const [inputStu,setInputStu] = useState(false); 
    const [keyword,setKeyword] = useState('');
    const [logout,setLogout] = useState(false);
    const [clean,setClean] = useState(false);
    const [historyList,setHistoryList] = useState([]);
    const classInput = classNames('search_input',{'inputStu':inputStu})
    const menu = [
        {key:'个人信息',path:"/my/userinfo"},
        {key:'我的收藏',path:"/my/collect"},
        {key:'我的订单',path:"/my/order"},
        {key:'修改密码',path:"/my/changePwd"},
        {key:'意见反馈',path:"/my/feedback"},
        {key:'清除缓存',path:"clean"},
        {key:'退出登录',path:"logout"},
    ]
    useEffect(() => {
        setKeyword(search.get('keyword')||'')
        if(pathname!=='/home') {
            setKeyword('')
        }
        console.log(search.get('code'))
        if (search.get('code')&&!Cookies.get('token')) {
            LoginStuService.handleLoginStu(true)
        }
    },[pathname,search]);
    useEffect(() => {
        if(Cookies.get('token')) {
            handleGetSearchHistory()
        }
    },[search]);
    function handleGetSearchHistory() {
        getSearchHistory({}).then(res=>{
            if(res.code===200) {
                setHistoryList(res.data)
            }
        })
    }
    function handleMenu(v) {
        if(v.path==='logout') {
            setLogout(true)
            return
        }
        if(v.path==='clean') {
            setClean(true)
            return
        }
        navigate(v.path)
    }
   function handleLogout() {
        Cookies.remove('token')
        Cookies.remove('userinfo')
        setLogout(false)
        navigate('/')
   }
   function handleGetClearSearchHistory() {
    getClearSearchHistory({}).then(res=>{
        if(res.code===200) {
            handleGetSearchHistory()
            message.success('清除成功')
        }
    })
   }
    return(
        <div className='nav'>
            <div className='nav_con'>
                <div className='nav_con_left'>
                    <div className='logo' onClick={()=>{navigate('/')}}>
                        <img src={require("images/logo.png")} alt="" />
                    </div>
                    <div className='search'>
                        <div className={classInput}>
                       
                            <input onFocus={()=>setInputStu(true)} 
                                    value={keyword} 
                                    onInput={(e)=>setKeyword(e.target.value)}  
                                    onBlur={()=>setInputStu(false)} 
                                    placeholder='输入课程名称进行搜索' 
                                    type="text" />
                            <div className='search_btn' onClick={()=>{Cookies.get('token')?navigate('/home?keyword='+keyword):LoginStuService.handleLoginStu(true)}}>搜索</div>
                        </div>
                        {Cookies.get('token')&&keyword&&keyword!==search.get('keyword')?
                        <div className='search_pop'>
                            <div className='search_history'>
                                <p>历史搜索</p>
                                <img src={require("images/del_search.png")} onClick={()=>handleGetClearSearchHistory()} alt="" />
                            </div>
                            <div className='search_list'>
                                {historyList.map((v,i)=>{
                                    return(
                                        <div key={i} className='search_item' onClick={()=>setKeyword(v.content)}>{v.content}</div>
                                    )
                                })}   
                            </div>
                        </div>:""}
                    </div>
                </div>
                <div className='nav_con_right'>
                    <div className='tab'>
                        <div className={pathname==='/home'?'active tab_item':'tab_item'} onClick={()=>{navigate('/home')}}>在线课程</div>
                        <div className={pathname==='/live'?'active tab_item':'tab_item'} onClick={()=>{navigate('/live')}}>直播课程</div>
                        {pathname==='/home' || pathname==='/live'?<div className='bot' style={{transform:pathname==='/home'?'translateX(0)':'translateX(102px)'}}></div>:''}
                    </div>
                    <div className='nav_div'></div>
                    <div className='nav_msg cursor' onClick={()=>{navigate('/news')}}>
                        <img src={require("images/info.png")} alt="" />
                        {Cookies.get('userinfo')&&JSON.parse(Cookies.get('userinfo')).message_flag!='0'?<div className='count'>{JSON.parse(Cookies.get('userinfo')).message_flag}</div>:''}
                    </div>
                    {!Cookies.get('token')?
                    <div className='user_info' onClick={()=>{ LoginStuService.handleLoginStu(true)}}>
                        <img src={require("images/MR_img.png")} alt="" />
                        <div className='user_info_name cursor'>登录/注册</div>
                    </div>
                     :
                     <div className='user_info cursor user_info_hover'>
                        <div className='user_img'>
                            <img src={JSON.parse(Cookies.get('userinfo')).avatar||require("images/MR_img.png")} alt="" />
                        </div>
                        <div className='user_name'>
                            <p>{JSON.parse(Cookies.get('userinfo')).nickname}</p>
                            <img className='user_bot' src={require("images/user_bot.png")} alt="" />
                        </div>
                        <div className='user_info_pop'>
                            {
                                menu.map((v,i)=>{
                                    const userBor = classNames('user_info_pop_item',{'userBor':i===6})
                                    return(
                                        <div key={i} onClick={()=>{handleMenu(v)}} className={userBor}>{v.key}</div>
                                    )
                                })
                            }
                           
                        </div>
                    </div>}
                </div>
            </div>
            {logout?
            <div className='logout_pop'>
                <div className='logout'>
                    <div className='title'>
                        <QuestionCircleOutlined style={{ fontSize: '22px', color: '#FAAD14' }}/>
                        <h2>退出登录</h2>
                    </div>
                    <div className='msg'>是否确定退出登录？</div>
                    <div className='logout_pop_btn'>
                        <div className='btn1' onClick={()=>{setLogout(false)}}>取 消</div>
                        <div className='btn2' onClick={()=>handleLogout()}>确 定</div>
                    </div>
                </div>
            </div>:''}
            <Clean clean={clean} setClean={setClean}/>
        </div>
    )
}
export default Nav