import { useState,useEffect,useRef } from 'react'
import Pay from 'components/pay'
import { useNavigate  } from 'react-router-dom';
import { getUserOrderList,orderPreview } from 'api'
var winH = document.documentElement.clientHeight //获取设备高度
const MyOrder = () =>{
    const [payPopStu,setPayPopStu] = useState(false)
    const tabs = ['录播','直播']
    const [tabStu,setTabStu] = useState(0)
    const [page,setPage] = useState(1)
    const [list,setList] = useState([])
    const [detail,setDetail] = useState({})
    const navigate = useNavigate()
    const more = useRef()
    const [loadText,setLoadText] = useState('查看更多')
    function handleClickTo(v) {
        setPayPopStu(true)
        if(tabStu===0) {
            navigate('/detail?id='+v.oid+'&stu=1&key=order')
        }else{
            if(v.live_status===0) {
                orderPreview({
                    type:10,
                    oid:v.oid
                }).then(res=>{
                    if(res.code===200) {
                        setDetail(res.data)
                    }
                })
            }else if(v.live_status===1){
                navigate('/detail?id='+v.oid+'&stu=2&key=live')
            }else if(v.live_status===2){
                navigate('/detail?id='+v.oid+'&stu=3&key=back')
            }
        }
    }
    useEffect(() => {
        var timer=null
        function handle(params) {
            return function () {
            if(timer){
                clearTimeout(timer)
            }
            timer = setTimeout(() => {
                setPage(page+1)
            }, 500);
            }
        } 
        const scrollChange = (e) => {
            console.log(parseInt(more.current.getBoundingClientRect().top),winH)
            if(parseInt(more.current.getBoundingClientRect().top) <= winH){//getBoundingClientRect方法可获取节点距离窗口的位置
                setLoadText('加载中...')
                handle()()
            }
          }
        // 滚动条滚动时触发
        window.addEventListener("scroll",scrollChange)
        return () => {
            window.removeEventListener('scroll', scrollChange,false)
        }
      }, [page])
    function handleReset() {
        setList([]);
        setLoadText('查看更多')
        setPage(1);
    }
    useEffect(()=>{
        function handleGetUserOrderList(){
            getUserOrderList({
                page:page,
                type:tabStu===0?5:10
            }).then(res=>{
                if(res.code===200) {
                    console.log(list)
                    if(!res.data||res.data.length===0){
                        setLoadText('没有更多数据')
                    }
                    setList([...list,...res.data])
                    winH=document.documentElement.clientHeight
                }
            })
        }
        handleGetUserOrderList()
    },[tabStu,page])
   
    return(
        <div className="my_order">
            <div className='tab_main'>
                <div className='tab_list'>
                    {
                        tabs.map((v,i)=>{
                            return(
                                <div key={i} className={tabStu===i?'tab_list_item active':'tab_list_item'} onClick={()=>{handleReset();setTabStu(i);}}>{v}</div>
                            )
                        })
                    }
                    <div className='tab_list_item_bot' style={{transform:'translateX('+tabStu*100+'px)'}}></div>
                </div>
            </div>
            {console.log(list)}
            {detail?<Pay popType='3' detail={detail} payPopStu={payPopStu} setPayPopStu={setPayPopStu}/>:''}
            {
                
                list.map((v,i)=>{
                    return(
                        <div className="order_item cursor" key={i} onClick={()=>{handleClickTo(v)}}>
                            <div className="order_top">
                                <img src={v.pic} alt="" />
                                <div className='info'>
                                    <p className='p1'>{v.name || v.title}</p>
                                    <p className='p2'>{v.desc}</p>
                                    <p className='p3'>{v.create_time}</p>
                                </div>
                                <div className='i'></div>
                                <div className='price'>¥{v.money}</div>
                                <div className='methed'>{v.pay_type===2?'微信支付':'支付宝支付'}</div>
                            </div>
                            <div className="order_bot">
                                <p>购买时间</p>
                                <p className="time">{v.create_time}</p>
                            </div>
                        </div>
                    )
                })
            }
             <div ref={more} onClick={()=>(loadText==='查看更多'?setPage(page+1):'')} className="bottom_more cursor">
                {loadText}
            </div>
        </div>
    )
}
export default MyOrder