import { useState } from 'react'
import UserInfo from 'pages/my/userinfo'
import MyCollect from 'pages/my/collect'
import MyOrder from 'pages/my/order'
import ChangePwd from 'pages/my/changePwd'
import Feedback from 'pages/my/feedback'
import Menu from 'components/menu'
import { useNavigate,useLocation } from 'react-router-dom'
import  Cookies from 'js-cookie'
import './index.scss'
const My = () =>{
    const {pathname} = useLocation(); 
    const [userinfo,setUserinfo] = useState(JSON.parse(Cookies.get('userinfo')))
    return(
        <div className="my">
            <div className="header">
                <div className='photo'>
                    <div className='img'>
                        <img src={userinfo.avatar||require('images/MR_img.png')} alt="" />
                    </div>
                    <p>{userinfo.nickname}</p>
                </div>
            </div>
            <div className='content'>
                <div className='left'>
                    <Menu/>
                </div>
                <div className='right'>
                    <div className='title'>
                        { 
                            pathname==='/my/userinfo'? '个人信息':
                            pathname==='/my/collect'? '我的收藏':
                            pathname==='/my/order'? '我的订单':
                            pathname==='/my/changePwd'? '修改密码':
                            pathname==='/my/feedback'? '意见反馈':''
                        }
                    </div>
                    {
                        pathname==='/my/userinfo'? <UserInfo/>:
                        pathname==='/my/collect'? <MyCollect/>:
                        pathname==='/my/order'? <MyOrder/>:
                        pathname==='/my/changePwd'? <ChangePwd/>:
                        pathname==='/my/feedback'? <Feedback/>:''
                    }
                   
                </div>
            </div>
        </div>
    )
}
export default My