import React,{useEffect,useState,useContext} from 'react'
// 引入路由守卫
import { Routes,Route,useLocation,useNavigate,Navigate } from 'react-router-dom';

import Home from 'pages/home' 
import Live from 'pages/live' 
import Forget from 'pages/forget' 
import Detail from 'pages/detail'
import News from 'pages/news'
import NewsDetail from 'pages/news/detail'
import Agreement from 'pages/agreement'
import My from 'pages/my'
import  Cookies from 'js-cookie'
import { loginStuService } from 'utils/loginStu';
import { getUserInfo } from 'api'
const routers = [
    {
        path: '/',
        element:<Navigate to='/home' replace />,
        auth:false,
    },
    {
      path: '/home',
      element: <Home/>,
      auth:false,
    },
    {
      path: '/live',
      element:<Live/>,
      auth:true,
    },
    {
      path: '/forget',
      element:<Forget/>,
      auth:false,
    },
    {
      path: '/detail',
      element:<Detail/>,
      auth:false,
    },
    {
      path: '/news',
      element:<News/>,
      auth:true,
    },
    {
      path: '/news/detail',
      element:<NewsDetail/>,
      auth:true,
    },
    {
      path: '/agreement',
      element:<Agreement/>,
      auth:false,
    },
    {
      path: '/my/userinfo',
      element:<My/>,
      auth:true,
    },
    {
      path: '/my/collect',
      element:<My/>,
      auth:true,
    },
    {
      path: '/my/order',
      element:<My/>,
      auth:true,
    },
    {
      path: '/my/changePwd',
      element:<My/>,
      auth:true,
    },
    {
      path: '/my/feedback',
      element:<My/>,
      auth:true,
    },
    {
      path: '*',
      element: <Home /> // 注意，404页面是不需要路由守卫的！
    }
]
const RouterConfig = () =>{
  const LoginStuService = useContext(loginStuService)
  let isLogin = Cookies.get('token')
  const {pathname,search} = useLocation()
  const navigate = useNavigate()
  const [url,setUrl] = useState('/')
  useEffect(()=>{
    routers.map((v,i) => {
      if(v.path===pathname) {
         if(!isLogin&&v.auth) {
          navigate(url,{replace:true})
          LoginStuService.handleLoginStu(true)
        }else{
          setUrl(pathname+search)
        }
      }
    })
  },[pathname,search,url,isLogin,navigate,LoginStuService])
  useEffect(()=>{
    if(isLogin) {
        getUserInfo({}).then(res=>{
          if(res.code==200) {
              Cookies.set('userinfo',JSON.stringify(res.data))
          }
      })
    }
},[pathname])
  return(
    <div>
      <Routes>
        {routers.map((v,i)=>{
          return(
            <Route key={i} path={v.path} element={!isLogin&&v.auth?pathname===v.path?v.element:'':v.element}/>
          )
        })}
      </Routes>
    </div>
    )
}

export default RouterConfig ;