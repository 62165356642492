import React,{ useState } from 'react'
import './index.scss'
import { useNavigate } from 'react-router-dom'
import {getForget,getNewPasswordTips,GetCode } from 'api'
import { message } from 'antd';
const Forget = () =>{
    const navigate = useNavigate()
    const [forgetForm,setForgetForm] = useState({
        mobile:"",
        code:"",
        password:"",
        confirm_password:"",
    })
    const [tips,setTips] = useState()
    const [inputType,setInputType] = useState('password')
    const [inputType1,setInputType1] = useState('password')
    const [messageBtn,setmessageBtn] = useState('获取验证码')
    function hanldeForget() {
         if(!forgetForm.mobile) {
            message.error('请输入手机号')
            return
        }
        if(!/^1[3-9]\d{9}$/.test(forgetForm.mobile)) {
            message.error('请输入正确手机号')
            return
        }
        if(!forgetForm.code) {
            message.error('请输入验证码')
            return
        }
        if(!forgetForm.password) {
            message.error('请输入密码')
            return
        }
        if(!forgetForm.confirm_password) {
            message.error('请输入确认密码')
            return
        }
        if(forgetForm.confirm_password !== forgetForm.password) {
            message.error('两次密码输入不一致')
            return
        }
        getForget(forgetForm).then(res=>{
            if(res.code===200) {
                 message.success('密码修改成功，请重新登录')
                 navigate('/')
            }else{
                message.success(res.msg)
            }
        })
    }
    function handleMobileInput(e) {
        setForgetForm({...forgetForm,mobile:e.target.value})
        if(/^1[3-9]\d{9}$/.test(e.target.value)) {
            handleTips(e.target.value)
        }
    }
    function handleTips(m) {
        getNewPasswordTips({
            mobile:m
        }).then(res=>{
            if(res.code===200) {
                setTips(res.data.password_alert)
            }
        })
    }
    function handleGetCode() {
        if(!forgetForm.mobile) {
            message.error('请输入手机号')
            return
        }
        if(!/^1[3-9]\d{9}$/.test(forgetForm.mobile)) {
            message.error('请输入正确手机号')
            return
        }
        GetCode({mobile:forgetForm.mobile,is_forget:1}).then(res=>{
            if(res.code===200) {
                message.success('获取成功')
                let i = 90;
                let timer = setInterval(() => {
                    setmessageBtn("重新获取(" + i + ")") 
                    i--;
                    if (i < 0) {
                        setmessageBtn("重新获取")
                        clearInterval(timer);
                    }
                }, 1000);
            }else{
                message.error(res.msg)
            }
        })
    }
    return(
        <div className='forget'>
            <div className='left'>
                <img src={require('images/forget_left.png')} alt="" />
            </div>
            <div className='right'>
                <div className='title'>密码管理</div>
                <div className='right_con'>
                    <div className='input_item' style={{marginTop:'50px'}}>
                        <img className='img1' src={require('images/login_phone.png')} alt="" />
                        <input type="text" maxLength={11} onInput={(e)=>handleMobileInput(e)} value={forgetForm.mobile} placeholder='请输入手机号'/>
                    </div>
                    <div className='input_item mt30'>
                        <img className='img1' src={require('images/login_code.png')} alt="" />
                        <input type="text" value={forgetForm.code} onInput={(e)=>setForgetForm({...forgetForm,code:e.target.value})} placeholder='请输入验证码'/>
                        <p onClick={()=>messageBtn==='获取验证码' || messageBtn==='重新获取'?handleGetCode():''}>{ messageBtn }</p>
                    </div>
                    <div className='input_item mt30'>
                        <img className='img1' src={require('images/login_pwd.png')} alt="" />
                        <input type={inputType} value={forgetForm.password}  onInput={(e)=>setForgetForm({...forgetForm,password:e.target.value})} placeholder='请输入密码'/>
                        <img className='img2 cursor' onClick={()=>{setInputType(inputType==='text'?'password':'text')}} src={require('images/'+(inputType==='password'?'login_hide':'login_show')+'.png')} alt="" />
                    </div>
                    <div className='input_item mt30'>
                        <img className='img1' src={require('images/login_pwd.png')} alt="" />
                        <input type={inputType1} value={forgetForm.confirm_password}  onInput={(e)=>setForgetForm({...forgetForm,confirm_password:e.target.value})} placeholder='请再次输入密码'/>
                        <img className='img2 cursor' onClick={()=>{setInputType1(inputType1==='text'?'password':'text')}} src={require('images/'+(inputType1==='password'?'login_hide':'login_show')+'.png')} alt="" />
                    </div>
                    {tips?<div className='tips'>密码提示语：{tips}</div>:""}
                    <div className='login_btn mt30' onClick={()=>hanldeForget()}>完成</div>
                </div>
                
            </div>
        </div>
    )
}
export default Forget