import './index.scss'
import { getConfig,getSlideDetail } from 'api'
import { useState,useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
const Agreement = () =>{
    const [search] = useSearchParams()
    const [content,setContent] = useState()
    const [detail,setDetail] = useState({})
    useEffect(()=>{
        console.log(search.get('stu'))
        if(search.get('stu')!=='3') {
            getConfig().then(res=>{
                if(search.get('stu')==='1') {
                    setContent(res.data.agreement.user_ysxy)
                }else{
                    setContent(res.data.agreement.user_yhxy)
                }
            })
        }else{
            getSlideDetail({
                id:search.get('id')
            }).then(res=>{
                if(res.code===200) {
                    setDetail(res.data)
                    setContent(formatRichText(res.data.path_content))
                }
            })
        }
    },[])
    /**
     * 处理富文本里自带的图片大小，添加自定义的图片大小
     * 1.去掉img标签里的style、width、height属性
     * 2.img标签添加style属性：width:100%;height:auto
     * 3.修改所有style里的width属性为max-width:100%
     * 4.去掉<br/>标签
     */
    function formatRichText(notice) {
        let newContent = notice.toString().replace(/<img[^>]*>/gi, function(match, capture) {
            // match = match.replace(/style="[^"]+"/gi, '').replace(/style=\"(.*)\"/gi, '');
            //重点改了这里，直接删掉了存在的style，无论是在前还是后的style
            match = match.toString().replace(/style="[^"]+"/gi, '').replace(
                /style\s*?=\s*?([‘"])[\s\S]*?\1/ig, '');
            match = match.toString().replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
            match = match.toString().replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
            return match;
        });
        newContent = newContent.toString().replace(/style="[^"]+"/gi, function(match, capture) {
            match = match.toString().replace(/width:[^;]+;/gi, 'width:100%;').replace(/width:[^;]+;/gi,
                'width:100%;');
            return match;
        });
        newContent = newContent.toString().replace(/<br[^>]*\/>/gi, '');
        newContent = newContent.toString().replace(/\<img/gi, '<img style="width:100%;height:auto;"');
        return newContent;
    }
    return(
        <div className="news_detail">
            <div className="title">{search.get('stu')==='1'?'技考帮隐私协议':search.get('stu')==='2'?'技考帮用户协议':search.get('stu')==='3'?detail.title:''}</div>
            <div className="content" dangerouslySetInnerHTML={{__html:content }}></div>
        </div>
    )
}
export default Agreement