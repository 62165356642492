import { getMessageDetail }from'api'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
const NewsDetail = () =>{
    const [detail,setDetail] = useState({})
    const [search] = useSearchParams()
    useEffect(()=>{
        handleGetMessageDetail()
    },[])
    function handleGetMessageDetail() {
        getMessageDetail({message_id:search.get('id')}).then(res=>{
            if(res.code===200) {
                setDetail(res.data)
            }
        })
    }
    return(
        <div className="news_detail">
            <div className="title">{detail.title}</div>
            <div className="time">发布时间：{detail.create_time}</div>
            <div className="content" dangerouslySetInnerHTML={{__html:detail.content}}></div>
        </div>
    )
}
export default NewsDetail