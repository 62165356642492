import './index.scss'
import { useState,useEffect } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { message } from 'antd'
const Clean = (props) =>{
    function handleClean() {
        message.success('清除成功！')
        props.setClean(false)
    }
    return(
        <div>
            {props.clean?
            <div className='logout_pop'>
                <div className='logout'>
                    <div className='title'>
                        <QuestionCircleOutlined style={{ fontSize: '22px', color: '#FAAD14' }}/>
                        <h2>清除缓存</h2>
                    </div>
                    <div className='msg'>是否确定清除缓存 230KB？</div>
                    <div className='logout_pop_btn'>
                        <div className='btn1' onClick={()=>{props.setClean(false)}}>取 消</div>
                        <div className='btn2' onClick={()=>{handleClean()}}>确 定</div>
                    </div>
                </div>
            </div>:''}
        </div>
    )
}
export default Clean