import React,{ useState,useContext, useEffect } from 'react'
import { useNavigate,useSearchParams } from 'react-router-dom'
import { Checkbox,message } from 'antd';
import './index.scss'
import { getLogin,getRegister,getUserInfo,GetCode,oauthUserinfo,oauthMobile } from 'api'
import  Cookies from 'js-cookie'
import { loginStuService } from 'utils/loginStu';
// import QC from 'qc' 
// import WB2 from 'WB2' 
const Login =()=>{
    console.log(window.location)
    const navigate = useNavigate()
    const LoginStuService = useContext(loginStuService);
    const [checked,setChecked] = useState(true)
    const [pageStu,setPageStu] = useState(1)
    const [search] = useSearchParams() 
    const [inputType,setInputType] = useState('password')
    const [inputType1,setInputType1] = useState('password')
    const [inputType2,setInputType2] = useState('password')
    const [messageBtn,setmessageBtn] = useState('获取验证码')
    const [qrcodeStu,setQrcodeStu] = useState('')
    function handleTo(stu) {
        navigate('/agreement?stu='+stu);
        setPageStu(1)
        LoginStuService.handleLoginStu(false)
    }
    const [mobile,setMobile] = useState()
    const [password,setPassword] = useState()
    const [regForm,setRegForm] = useState({
        nickname:"",
        mobile:"",
        code:"",
        password:"",
        confirm_password:"",
        password_alert:"",
    })
    const [regForm1,setRegForm1] = useState({
        channel:"pc",
        mobile:"",
        code:"",
        password:"",
        confirm_password:"",
        ua_id:"",
    })
    function handlePageStu() {
        if(!regForm.nickname) {
            message.error('请输入用户名')
            return
        }
        if(!regForm.mobile) {
            message.error('请输入手机号')
            return
        }
        if(!/^1[3-9]\d{9}$/.test(regForm.mobile)) {
            message.error('请输入正确手机号')
            return
        }
        if(!regForm.code) {
            message.error('请输入验证码')
            return
        }
        setPageStu(3)
    }
    function handleReg() {
       
        if(!regForm.password) {
            message.error('请输入密码')
            return
        }
        if(!regForm.confirm_password) {
            message.error('请输入确认密码')
            return
        }
        if(regForm.confirm_password !== regForm.password) {
            message.error('两次密码输入不一致')
            return
        }
        if(!regForm.password_alert) {
            message.error('请输入密码提示语')
            return
        }
        getRegister(regForm).then(res=>{
            if(res.code===200) {
                Cookies.set('token',res.data.token)
                message.success('注册成功')
                handleGetUserInfo()
            }else{
                message.error(res.msg)
            }
        })
    }
    function handleRegCode() {
        if(!regForm1.mobile) {
            message.error('请输入手机号')
            return
        }
        if(!/^1[3-9]\d{9}$/.test(regForm1.mobile)) {
            message.error('请输入正确手机号')
            return
        }
        if(!regForm1.code) {
            message.error('请输入验证码')
            return
        }
        // if(!regForm1.password) {
        //     message.error('请输入密码')
        //     return
        // }
        // if(!regForm1.confirm_password) {
        //     message.error('请输入确认密码')
        //     return
        // }
        // if(regForm1.confirm_password !== regForm1.password) {
        //     message.error('两次密码输入不一致')
        //     return
        // }
        oauthMobile(regForm1).then(res=>{
            if(res.code===200) {
                Cookies.set('token',res.data.token)
                handleGetUserInfo()
            }else{
                message.error(res.msg)
            }
        })
    }
    function handleGetLogin() {
        if(!checked) {
            message.error('请阅读并同意相关协议')
            return
        }
        if(!mobile) {
            message.error('请输入手机号')
            return
        }
        if(!password) {
            message.error('请输入密码')
            return
        }
        getLogin({
            mobile:mobile,
            password:password,
        }).then(res=>{
            console.log(res)
            if(res.code===200) {
                Cookies.set('token',res.data.token)
                handleGetUserInfo()
            }else{
                message.error(res.msg)
            }
        })
    }
    function handleGetUserInfo(token) {
        getUserInfo({}).then(res=>{
            if(res.code===200) {
                LoginStuService.handleLoginStu(false)
                Cookies.set('userinfo',JSON.stringify(res.data))
                message.success('登录成功')
            }else{
                message.error(res.msg)
            }
        })
    }
    function handleGetCode() {
        if(!regForm.mobile) {
            message.error('请输入手机号')
            return
        }
        if(!/^1[3-9]\d{9}$/.test(regForm.mobile)) {
            message.error('请输入正确手机号')
            return
        }
        GetCode({mobile:regForm.mobile}).then(res=>{
            if(res.code===200) {
                message.success('获取成功')
                let i = 90;
                let timer = setInterval(() => {
                    setmessageBtn("重新获取(" + i + ")") 
                    i--;
                    if (i < 0) {
                        setmessageBtn("重新获取")
                        clearInterval(timer);
                    }
                }, 1000);
            }else{
                message.error(res.msg)
            }
        })
    }
    function handleGetCode1() {
        if(!regForm1.mobile) {
            message.error('请输入手机号')
            return
        }
        if(!/^1[3-9]\d{9}$/.test(regForm1.mobile)) {
            message.error('请输入正确手机号')
            return
        }
        GetCode({mobile:regForm1.mobile}).then(res=>{
            if(res.code===200) {
                message.success('获取成功')
                let i = 90;
                let timer = setInterval(() => {
                    setmessageBtn("重新获取(" + i + ")") 
                    i--;
                    if (i < 0) {
                        setmessageBtn("重新获取")
                        clearInterval(timer);
                    }
                }, 1000);
            }else{
                message.error(res.msg)
            }
        })
    }
    useEffect(()=>{
        if(qrcodeStu===1) {
            wxLogin()
        } 
    },[qrcodeStu])
    useEffect(()=>{
        if(!LoginStuService.loginStu) {
            setPageStu(1)
            setQrcodeStu('')
        } 
    },[LoginStuService])
    useEffect(()=>{
        if(LoginStuService.loginStu&&search.get('code')&&search.get('state')==='wx'&&!Cookies.get('token')) {
           message.loading({content:'加载中...',duration:0})
            oauthUserinfo({
                code:search.get('code'),
                flag:1
            }).then(res=>{
                message.destroy()
                if(res.code===200) {
                    if(!res.data.is_mobile) {
                        setRegForm1({...regForm1,ua_id:res.data.ua_id})
                        setPageStu(4)
                        setQrcodeStu(10)
                    }else{
                        Cookies.set('token',res.data.token)
                        handleGetUserInfo()
                    }
                }else{
                    message.error(res.msg)
                }
            }) 
        } 
        if(search.get('state')==='qq'&&search.get('code')&&!Cookies.get('token')) {
            message.loading({content:'加载中...',duration:0})
            oauthUserinfo({
                code:search.get('code'),
                flag:2
            }).then(res=>{
                message.destroy()
                if(res.code===200) {
                    if(!res.data.is_mobile) {
                        setRegForm1({...regForm1,ua_id:res.data.ua_id})
                        setPageStu(4)
                        setQrcodeStu(10)
                    }else{
                        Cookies.set('token',res.data.token)
                        handleGetUserInfo()
                    }
                }else{
                    message.error(res.msg)
                }
            }) 
        } 
        if(search.get('state')==='wb'&&search.get('code')&&!Cookies.get('token')) {
            message.loading({content:'加载中...',duration:0})
            oauthUserinfo({
                code:search.get('code'),
                flag:3
            }).then(res=>{
                message.destroy()
                if(res.code===200) {
                    if(!res.data.is_mobile) {
                        setRegForm1({...regForm1,ua_id:res.data.ua_id})
                        setPageStu(4)
                        setQrcodeStu(10)
                    }else{
                        Cookies.set('token',res.data.token)
                        handleGetUserInfo()
                    }
                }else{
                    message.error(res.msg)
                }
            }) 
        } 
    },[search])
    function wxLogin() {
       new window.WxLogin({
            self_redirect:false,
            id:"qrcode", 
            appid: "wx56c397591cba62cc", 
            scope: "snsapi_login",
            redirect_uri: encodeURIComponent(window.location.href),
            state: "wx",
            style: "",
            href: ""
        });   //设置iframe标签可以进行跨域跳转
    }
    function wbLogin() {
        window.location.href=`https://api.weibo.com/oauth2/authorize?response_type=code&client_id=1176408708&redirect_uri=${encodeURIComponent('https://space.jkaobang.cn/#')}&state=wb&scope=all`
    }
    function qqLogin() {
        window.location.href=`https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=102036041&redirect_uri=${encodeURIComponent('https://space.jkaobang.cn/#')}&state=qq&scope=all`
        // if(QC.Login.check()){//检查是否已登录
        //     QC.Login.signOut();//退出登录
        // }else{
        //     QC.Login.showPopup({ appId:"102036041", redirectURI:"https://space.jkaobang.cn/#" });
        //     QC.Login.getMe(function(openId, accessToken){
        //         oauthUserinfo({
        //             code:accessToken,
        //             flag:2
        //         }).then(res=>{
        //             if(res.code===200) {
        //                 if(!res.data.is_mobile) {
        //                     setRegForm1({...regForm1,ua_id:res.data.ua_id})
        //                     setPageStu(4)
        //                     setQrcodeStu(10)
        //                 }else{
        //                     Cookies.set('token',res.data.token)
        //                     handleGetUserInfo()
        //                 }
        //             }else{
        //                 message.error(res.msg)
        //             }
        //         }) 
        //         console.log(openId, accessToken)
        //     })
        // }
        // QC.api('get_user_info',{}).success(function(userdata){
        //     console.log(userdata)
        //     //可以获得用户的各种相关信息，如用户昵称
        //     // var username = userdata.data.nickname;
        // });
    }
    return(
        <div>
            {LoginStuService.loginStu?
            <div className='login login_bg'>
                <div className='login_content'>
                    <div className='login_content_left'>
                        <img src={require('images/login_left.png')} alt="" />
                    </div>
                    {pageStu===1?
                    <div className='login_content_right'>
                        <div className='input_item'>
                            <img className='img1' src={require('images/login_acc.png')} alt="" />
                            <input type="text" value={mobile} onInput={(e)=>setMobile(e.target.value)} placeholder='请输入手机号'/>
                        </div>
                        <div className='input_item mt20'>
                            <img className='img1' src={require('images/login_pwd.png')} alt="" />
                            <input type={inputType} value={password} onInput={(e)=>setPassword(e.target.value)} placeholder='请输入密码'/>
                            <img className='img2 cursor' onClick={()=>{setInputType(inputType==='text'?'password':'text')}} src={require('images/'+(inputType==='password'?'login_hide':'login_show')+'.png')} alt="" />
                        </div>
                        <div className='login_btn mt20' onClick={()=>handleGetLogin()}>
                            登录
                        </div>
                        <div className='forget_reg'>
                            <div className='left' onClick={()=>{navigate('/forget');LoginStuService.handleLoginStu(false)}}>忘记密码</div>
                            <div className='right' onClick={()=>{setPageStu(2)}}>去注册</div>
                        </div>
                        <div className='other_login mt20'>
                            <div className='title'>其他登录方式</div>
                            <div className='icon_list'>
                                <div className='icon_item' onClick={()=>{setPageStu(4);setQrcodeStu(1)}}>
                                    <img src={require('images/login_wx.png')} alt="" />
                                    <p>微信</p>
                                </div>
                                <div className='icon_item' onClick={()=>{setQrcodeStu(2);qqLogin()}}>
                                    <img src={require('images/login_qq.png')} alt="" />
                                    <p>QQ</p>
                                </div>
                                <div className='icon_item' onClick={()=>{setQrcodeStu(2);wbLogin()}}>
                                    <img src={require('images/login_wb.png')} alt="" />
                                    <p>微博</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className='agrent' style={{marginTop: '45px'}}>
                            <Checkbox checked={checked} onChange={()=>{setChecked(!checked)}}>
                                <p className='agrent_p'> 已阅读并同意<span onClick={()=>handleTo(1)}>《隐私政策》</span>跟<span onClick={()=>handleTo(2)}>《用户协议》</span></p>
                            </Checkbox>
                        </div>
                    </div>
                    : pageStu===2?
                    <div className='login_content_right'>
                        <div className='input_item'>
                            <img className='img1' src={require('images/login_acc.png')} alt="" />
                            <input type="text" onInput={(e)=>setRegForm({...regForm,nickname:e.target.value})} value={regForm.nickname} placeholder='请输入用户名'/>
                        </div>
                        <div className='input_item mt20'>
                            <img className='img1' src={require('images/login_phone.png')} alt="" />
                            <input type="text" onInput={(e)=>setRegForm({...regForm,mobile:e.target.value})} value={regForm.mobile} placeholder='请输入手机号'/>
                        </div>
                        <div className='input_item mt20'>
                            <img className='img1' src={require('images/login_code.png')} alt="" />
                            <input type="text" onInput={(e)=>setRegForm({...regForm,code:e.target.value})} value={regForm.code} placeholder='请输入验证码'/>
                            <p onClick={()=>messageBtn==='获取验证码' || messageBtn==='重新获取'?handleGetCode():''}>{ messageBtn }</p>
                        </div>
                        <div className='login_btn' onClick={()=>{handlePageStu()}} style={{marginTop:'40px'}}>
                            下一步
                        </div>
                        <div className='forget_reg'>
                            <div className='left'></div>
                            <div className='right' onClick={()=>{setPageStu(1)}}>去登录</div>
                        </div>
                        <div className='agrent' style={{marginTop: '13px'}}>
                            <Checkbox checked={checked} onChange={()=>{setChecked(!checked)}}>
                                <p className='agrent_p'> 已阅读并同意<span onClick={()=>{handleTo(1)}}>《隐私政策》</span>跟<span onClick={()=>{handleTo(2)}}>《用户协议》</span></p>
                            </Checkbox>
                        </div>
                    </div>
                    : pageStu===3?
                    <div className='login_content_right'>
                        <div className='input_item'>
                            <img className='img1' src={require('images/login_pwd.png')} alt="" />
                            <input type={inputType1} value={regForm.password} onInput={(e)=>setRegForm({...regForm,password:e.target.value})} placeholder='请输入密码'/>
                            <img className='img2 cursor' onClick={()=>{setInputType1(inputType1==='text'?'password':'text')}} src={require('images/'+(inputType1==='password'?'login_hide':'login_show')+'.png')} alt="" />
                        </div>
                        <div className='input_item mt20'>
                            <img className='img1' src={require('images/login_pwd.png')} alt="" />
                            <input type={inputType2} value={regForm.confirm_password} onInput={(e)=>setRegForm({...regForm,confirm_password:e.target.value})} placeholder='请再次输入密码'/>
                            <img className='img2 cursor' onClick={()=>{setInputType2(inputType2==='text'?'password':'text')}} src={require('images/'+(inputType2==='password'?'login_hide':'login_show')+'.png')} alt="" />
                        </div>
                        <div className='input_item mt20'>
                            <img className='img1' src={require('images/tips.png')} alt="" />
                            <input type="text" value={regForm.password_alert} onInput={(e)=>setRegForm({...regForm,password_alert:e.target.value})} placeholder='请输入密码提示语'/>
                        </div>
                        <div className='login_btn' style={{marginTop:'40px'}} onClick={()=>handleReg()}>
                            注册
                        </div>
                        <div className='forget_reg'>
                            <div className='left'></div>
                            <div className='right' onClick={()=>{setPageStu(1)}}>去登录</div>
                        </div>
                        <div className='agrent' style={{marginTop: '13px'}}>
                            <Checkbox checked={checked} onChange={()=>{setChecked(!checked)}}>
                                <p className='agrent_p'> 已阅读并同意<span onClick={()=>{handleTo(1)}}>《隐私政策》</span>跟<span onClick={()=>{handleTo(2)}}>《用户协议》</span></p>
                            </Checkbox>
                        </div>
                    </div>
                    :pageStu===4?
                    <div style={{flex:1,display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',}}>
                        <div style={{display:qrcodeStu===1?'inline-flex':'none'}} className='login_content_right qrcode' id='qrcode'></div>
                        <div className='login_content_right' style={{display:qrcodeStu===1?'none':'inline-flex'}}>
                            <div className='input_item'>
                                <img className='img1' src={require('images/login_phone.png')} alt="" />
                                <input type="text" onInput={(e)=>setRegForm1({...regForm1,mobile:e.target.value})} value={regForm1.mobile} placeholder='请输入手机号'/>
                            </div>
                            <div className='input_item mt20'>
                                <img className='img1' src={require('images/login_code.png')} alt="" />
                                <input type="text" onInput={(e)=>setRegForm1({...regForm1,code:e.target.value})} value={regForm1.code} placeholder='请输入验证码'/>
                                <p onClick={()=>messageBtn==='获取验证码' || messageBtn==='重新获取'?handleGetCode1():''}>{ messageBtn }</p>
                            </div>
                            {/* <div className='input_item mt20'>
                                <img className='img1' src={require('images/login_pwd.png')} alt="" />
                                <input type={inputType1} onInput={(e)=>setRegForm1({...regForm1,password:e.target.value})} value={regForm1.password} placeholder='请输入密码'/>
                                <img className='img2' onClick={()=>{setInputType1(inputType1==='text'?'password':'text')}} src={require('images/'+(inputType1==='password'?'login_hide':'login_show')+'.png')} alt="" />
                            </div>
                            <div className='input_item mt20'>
                                <img className='img1' src={require('images/login_pwd.png')} alt="" />
                                <input type={inputType2} onInput={(e)=>setRegForm1({...regForm1,confirm_password:e.target.value})} value={regForm1.confirm_password} placeholder='请再次输入密码'/>
                                <img className='img2' onClick={()=>{setInputType2(inputType2==='text'?'password':'text')}} src={require('images/'+(inputType2==='password'?'login_hide':'login_show')+'.png')} alt="" />
                            </div> */}
                            <div className='other_btn'>
                                <div className='other_btn1' onClick={()=>{LoginStuService.handleLoginStu(false)}}>取消</div>
                                <div className='other_btn2' onClick={()=>{handleRegCode()}}>完成</div>
                            </div>
                        </div>
                    </div>:''
                    }
                    <div className='login_close cursor' onClick={()=>{LoginStuService.handleLoginStu(false)}}>
                        <img src={require('images/login_close.png')} alt="" />
                    </div>
                </div>
            </div>
                :''}
        </div>
    )
}
export default Login