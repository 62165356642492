import './index.scss'
import { useNavigate } from 'react-router-dom'
import {messageList,deleteMessage} from 'api'
import React from 'react'
import {message,Modal} from 'antd'
var winH = document.documentElement.clientHeight //获取设备高度
const News = () =>{
    const navigate = useNavigate()
    const [list,setList] = React.useState([])
    const [page,setPage] = React.useState(1)
    const more = React.useRef()
    const [loadText,setLoadText] = React.useState('查看更多')
    React.useEffect(()=>{
        function handleMessageList() {
            messageList({page:page}).then(res=>{
                if(res.code===200) {
                    var arr = JSON.parse(JSON.stringify(list)) 
                    console.log(res.data)
                    res.data&&res.data.map(item=>{
                       return arr.push(item)
                    })
                    if((res.data&&res.data.length===0) || !res.data){
                        setLoadText('没有更多数据')
                    }
                    setList(arr)
                    winH=document.documentElement.clientHeight
                }
            })
        }
        handleMessageList()
    },[page])
    React.useEffect(() => {
        var timer=null
        function handle(params) {
            return function () {
            if(timer){
                clearTimeout(timer)
            }
            timer = setTimeout(() => {
                setPage(page+1)
            }, 500);
            }
        } 
        const scrollChange = (e) => {
            console.log(parseInt(more.current.getBoundingClientRect().top),winH)
            if(parseInt(more.current.getBoundingClientRect().top) <= winH){//getBoundingClientRect方法可获取节点距离窗口的位置
                setLoadText('加载中...')
                handle()()
            }
          }
        // 滚动条滚动时触发
        window.addEventListener("scroll",scrollChange)
        return () => {
            window.removeEventListener('scroll', scrollChange,false)
        }
      }, [page])
    function handleDeleteMessage(id,i) {
        Modal.confirm({
            title: '提示！',
            content: '是否删除当前消息！',
            okText:"确定",
            cancelText:'取消',
            onOk:()=>{
                deleteMessage({message_id:id}).then(res=>{
                    if(res.code===200) {
                        var arr = list.filter((v,idx)=>{
                            return i !== idx 
                        })
                        setList(arr)
                        message.success('删除成功')
                    }else{
                        message.error(res.msg)
                    }
                })
            }
        })
        
    }
    return(
        <div className="news">
            <div className="title">消息中心</div>
            <div className="news_list">
                {console.log(list)}
                {list.map((v,i)=>{
                    return(
                        <div className="news_item">
                            <div className='item_con cursor' onClick={()=>{navigate('/news/detail?id='+v.id)}}>
                                <div className={v.is_read==0?'h1 h1_a':'h1'}>
                                    <div className='name'>{v.title}</div>
                                    <div className='time'>{v.create_time}</div>
                                </div>
                                <div className='h4'>{v.content}</div>
                            </div>
                            <div className='item_btn cursor' onClick={()=>{handleDeleteMessage(v.id,i)}}>
                                <img src={require('images/del_search.png')} alt="" />
                                <p>删除</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div ref={more} onClick={()=>(loadText==='查看更多'?setPage(page+1):'')} className="bottom_more cursor">
                {loadText}
            </div>
        </div>
    )
}
export default News