import React from 'react'
import RouterConfig  from "router"
import Nav from 'components/nav'
import 'style/index.scss'
import Login from 'components/login' 
import { loginStuService, useRootLoginStuService } from 'utils/loginStu';
if(window.require) {
  const electron =  window.require('electron')
  const devInnerWidth = 1440 // 开发时的InnerHeight
  electron.ipcRenderer.on('size', (e, data) => {
    console.log(data.currentSize[0]); // 打印出 {a: 1}
    electron.webFrame.setZoomFactor(data.currentSize[0]/devInnerWidth)
  })
}

function App() {
  return (
    <loginStuService.Provider value={useRootLoginStuService()}>
      {/* style={css} */}
      <div  className="box_jkb">
        {/* loginStu={loginStu} setLoginStu={setLoginStu} */}
        <Nav/>
        <div style={{height:'64px'}}></div>
        {/* loginStu={loginStu} setLoginStu={setLoginStu} */}
        <Login/>
        {/* loginStu={loginStu} setLoginStu={setLoginStu} */}
        <RouterConfig/>
      </div>
    </loginStuService.Provider>
  );
}
export default App;
