import { userFeedback } from 'api'
import {message} from 'antd'
import React from 'react'
const Feedback = () =>{
    const [text,setText] = React.useState()
    function handleFeedback() {
        if(!text) {
            message.error('请输入反馈内容')
            return
        }
        userFeedback({content:text}).then(res=>{
            if(res.code==200) {
                message.success('提交成功')
                setText('')
            }else{
                message.error(res.msg)
            }
        })
    }
    return(
        <div className="feedback">
            <div className="feedback_item">
                <p>意见反馈</p>
                <textarea value={text} onInput={(e)=>setText(e.target.value)} placeholder="请尽量详细描述您遇到的问题，有助于我们快速给到您反馈。"/> 
            </div>
            <div className="feedback_btn cursor" onClick={()=>{handleFeedback()}}>提 交</div>
        </div>
    )
}
export default Feedback