import './index.scss'
import {useEffect, useState} from 'react'
import { Modal, message } from 'antd';
const { confirm } = Modal;
var popstu = false
const Video = (props) =>{
    const [videoStu,setvideoStu] = useState('')
    useEffect(()=>{
        if(!props.detail.is_pay&&props.detail.course_video&&props.detail.course_video[props.listStu]&&props.detail.course_video[props.listStu].is_free!==0&&props.stu==='1') {
            setvideoStu('')
        }else if(!props.detail.is_pay&&props.stu==='3'&&props.detail.playback_android_price!=='0.00') {
            setvideoStu('')
        }else{
            setvideoStu(props.url || props.detail.record_video_url || (props.detail.course_video&&props.detail.course_video[props.listStu]&&props.detail.course_video[props.listStu].source))
        }
        var elevideo = document.getElementById("video");
        if(elevideo&&props.detail.course_video) {
            elevideo.addEventListener('ended', function () { //结束
                if(!popstu&&props.detail.exam_address) {
                    popstu = true
                    // style={{textAlign:'center'}}
                    confirm({
                        title: '提示!',
                        content: <div><p>视频已结束，请您跳转至考试地址!</p><p>{props.detail.exam_address}</p></div>,
                        cancelText:'取消',
                        okText:'复制链接',
                        onOk() {
                            wxShareCopy()
                            popstu = false
                        },
                        onCancel() {
                            popstu = false
                            console.log('Cancel');
                        },
                    });
                    console.log("播放结束");
                }
            }, false);
        }
    },[props])
    function wxShareCopy() {
        const el = document.createElement('input')
    // 给input元素赋值需要复制的文本
        el.setAttribute('value', props.detail.exam_address)
        // 将input元素插入页面
        document.body.appendChild(el)
        // 选中input元素的文本
        el.select()
        // 复制内容到剪贴板
        document.execCommand('copy')
        // 删除input元素
        document.body.removeChild(el)
        message.success('链接复制成功')
    }
    return(
        <div className='video'>
            <video id='video' poster={props.detail&&props.detail.cover || props.detail.live_cover} autoPlay src={videoStu} controls></video>
        </div>
    )
}
export default Video