/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */

 import axios from 'axios';
 import QS from 'qs';
 import { message,Modal } from 'antd';
 import  Cookies from 'js-cookie'
 import { useContext } from 'react'
 import { loginStuService } from 'utils/loginStu';
 import { ExclamationCircleFilled } from '@ant-design/icons';
 const { confirm } = Modal;
 // 请求超时时间
 axios.defaults.timeout = 200000;
 // 地址
 axios.defaults.baseURL = "https://mapi.jkaobang.cn"
 // 请求拦截器
 axios.interceptors.request.use(
     config => {
         return config;
     },
     error => {
         return Promise.error(error);
     }
 )
 var ispop = false
 // 响应拦截器
 axios.interceptors.response.use(
     response => {
        if(response.data.code===102) {
           if( Cookies.get('token')&&!ispop){
                ispop = true
                confirm({
                    title: '温馨提示',
                    icon: <ExclamationCircleFilled />,
                    content: '您的登录已过期！',
                    okText:'知道了',
                    cancelText:'取消',
                    onOk() {
                        Cookies.remove('token')
                        Cookies.remove('userinfo')
                        window.location.reload()
                    },
                    onCancel() {
                        Cookies.remove('token')
                        Cookies.remove('userinfo')
                        window.location.reload()
                    },
                });
                return
            }
        }
         if (response.status === 200) {
           
             return Promise.resolve(response);
         } else {
             return Promise.reject(response);
         }
     },
     // 服务器状态码不是200的情况
     error => {
         if (error.message.includes('timeout')) {
             message.error('网络超时');
             return Promise.reject(error);
         }
         console.log(error)
         if(error.code === "ERR_NETWORK") {
            message.error(error.message);
            return Promise.reject(error.message);
         }
         if (error.response.status) {
            const LoginStuService = useContext(loginStuService)
             switch (error.response.status) {
                case 400:
                    message.error('400,请求错误');
                     break;
                 case 404:
                     message.error('404,网络请求不存在');
                     break;
                 case 500:
                     message.error('500,服务器端错误');
                     break;
                     //其他错误，直接抛出错误提示
                 default:
                     message.error(error.response.data.msg || "Error")
             }
             return Promise.reject(error.response);
         }
     }
 );
 
 let timer = new Date().getTime().toString();
 /**
  * get方法，对应get请求
  * @param {String} url [请求的url地址]
  * @param {Object} params [请求时携带的参数]
  */
 export function GET(url, params) {
    if(Cookies.get('token')) {
        params.token = Cookies.get('token')
     }
     return new Promise((resolve, reject) => {
         axios.get(url + `?t=${timer}`, { //随机数 针对IE设置
                 params: params
             })
             .then(res => {
                 resolve(res.data);
             })
             .catch(err => {
                 reject(err)
             })
     });
 }
 /**
  * post方法，对应post请求
  * @param {String} url [请求的url地址]
  * @param {Object} params [请求时携带的参数]
  */
 export function POST(url, params) {
    if(Cookies.get('token')) {
       params.token = Cookies.get('token')
    }
     return new Promise((resolve, reject) => {
         axios.post(url, QS.stringify(params))
             .then(res => {
                 resolve(res.data);
             })
             .catch(err => {
                 reject(err)
             })
     });
 }
 
 
 export function POST_JSON(url, params) {
    if(Cookies.get('token')) {
        params.token = Cookies.get('token')
     }
     return new Promise((resolve, reject) => {
         axios.post(url, params)
             .then(res => {
                 resolve(res.data);
             })
             .catch(err => {
                 reject(err)
             })
     });
 }
 
 /**
  * post方法，对应post请求, form-data
  * @param {String} url [请求的url地址]
  * @param {Object} params [请求时携带的参数]
  */
 export function FILE(url, params) {
    if(Cookies.get('token')) {
        params.token = Cookies.get('token')
     }
     let formData = new FormData()
     for (var key in params) {
         formData.append(key, params[key])
     }
     return new Promise((resolve, reject) => {
         axios.post(url, formData)
             .then(res => {
                 resolve(res.data);
             })
             .catch(err => {
                 reject(err)
             })
     });
 }