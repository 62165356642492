import './index.scss'
import { useState,useEffect } from 'react';
import { useNavigate,useLocation } from 'react-router-dom'
import Clean from 'components/clean'
const MyMenu = () =>{
    const navigate = useNavigate()
    const {pathname} = useLocation(); 
    const [clean,setClean] = useState(false);
    const menuList = [
        {name:'个人信息',icon:require("images/my/userinfo.png"),icon_a:require("images/my/userinfo_a.png"),path:'/my/userinfo'},
        {name:'我的收藏',icon:require("images/my/collect.png"),icon_a:require("images/my/collect_a.png"),path:'/my/collect'},
        {name:'我的订单',icon:require("images/my/order.png"),icon_a:require("images/my/order_a.png"),path:'/my/order'},
        {name:'修改密码',icon:require("images/my/change_pwd.png"),icon_a:require("images/my/change_pwd_a.png"),path:'/my/changePwd'},
        {name:'意见反馈',icon:require("images/my/feedback.png"),icon_a:require("images/my/feedback_a.png"),path:'/my/feedback'},
        {name:'清除缓存',icon:require("images/my/del.png"),icon_a:require("images/my/del_a.png"),path:'clean'},
    ]
    function handleMenu(v) {
        if(v.path==='clean') {
            setClean(true)
            return
        }
        navigate(v.path)
    }
    return(
        <div className="menu">
            {
               menuList.map((v,i)=>{
                    return(
                        <div onClick={()=>{handleMenu(v)}} className={v.path===pathname?'menu_item cursor active':'menu_item cursor'}>
                            <img src={v.path===pathname?v.icon_a:v.icon} alt="" />
                            <p>{v.name}</p>
                        </div>
                    )
               }) 
            }
            <Clean clean={clean} setClean={setClean}/>
        </div>
    )
}
export default MyMenu